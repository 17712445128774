import { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Navigate, useLocation } from 'react-router-dom';
import { db } from '../../firebase';
import LoadingScreen from '../common/LoadingScreen'; // You'll need to create this

function RequireAuth({ children }) {
  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const location = useLocation();
  const auth = getAuth();

  useEffect(() => {
    const checkAuth = async () => {
      const user = auth.currentUser;
      
      if (!user) {
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        
        // Check if profile is complete (has dateOfBirth)
        if (!userData || !userData.dateOfBirth) {
          setLoading(false);
          return;
        }

        setIsAuthorized(true);
        setLoading(false);
      } catch (error) {
        console.error('Error checking auth:', error);
        setLoading(false);
      }
    };

    checkAuth();
  }, [auth]);

  if (loading) {
    return <LoadingScreen />;
  }

  if (!auth.currentUser) {
    // Redirect to login if not authenticated
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!isAuthorized) {
    // Redirect to profile completion if authenticated but profile incomplete
    return <Navigate to="/profile" state={{ from: location }} replace />;
  }

  return children;
}

export default RequireAuth; 