import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Typography, Paper, Box, Tabs, Tab } from '@mui/material';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import SEO from './common/SEO';  // Import SEO component
import './Detail.css';
import config from '../config';
import Research from './Research';
import ArticleDetail from './Article'; // Import ArticleDetail component

function Detail({ dataKey, detailKey, onLoadingComplete }) {
  const { supplementKey, conditionKey, articleId } = useParams(); // Include articleId
  const itemKey = dataKey === 'supplements' ? supplementKey : conditionKey; // Determine the correct key
  const [selectedItem, setSelectedItem] = useState(null);
  const [supplements, setSupplements] = useState([]);
  const [conditions, setConditions] = useState([]);
  const [itemName, setItemName] = useState('');
  const linkedItems = useRef(new Set());
  const scrollPosition = useRef(0); // Ref to store scroll position

  const navigate = useNavigate();
  const location = useLocation();

  // Determine the active tab based on the URL
  const getActiveTab = () => {
    if (location.pathname.includes('/research')) return 1;
    return 0;
  };

  const [activeTab, setActiveTab] = useState(getActiveTab());

  useEffect(() => {
    setActiveTab(getActiveTab());
  }, [location.pathname]);

  useEffect(() => {
    if (itemKey) {
      fetchData();
      fetchSupplements();
      fetchConditions();
    }
  }, [itemKey, onLoadingComplete, dataKey]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}${dataKey}%2F${itemKey}.json?alt=media`);
      setSelectedItem(response.data);
      if (onLoadingComplete) {
        onLoadingComplete();
      }
    } catch (error) {
      console.error(`Error fetching ${dataKey} details:`, error);
    }
  };

  const fetchSupplements = async () => {
    try {
      const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2Fsupplements.json?alt=media`);
      const supplementsList = response.data;
      setSupplements(supplementsList);

      if (dataKey === 'supplements') {
        const foundItem = supplementsList.find(supplement => supplement.key === itemKey);
        if (foundItem) {
          setItemName(foundItem.name);
        }
      }
    } catch (error) {
      console.error('Error fetching supplements:', error);
    }
  };

  const fetchConditions = async () => {
    try {
      const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2Fconditions.json?alt=media`);
      const conditionsList = response.data;
      setConditions(conditionsList);

      if (dataKey === 'conditions') {
        const foundItem = conditionsList.find(condition => condition.key === itemKey);
        if (foundItem) {
          setItemName(foundItem.name);
        }
      }
    } catch (error) {
      console.error('Error fetching conditions:', error);
    }
  };

  // Function to switch between tabs and navigate to routes
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 0) {
      navigate(`/${dataKey}/${itemKey}`); // Use itemKey to ensure correct navigation
    } else if (newValue === 1) {
      navigate(`/${dataKey}/${itemKey}/research`); // Use itemKey to ensure correct navigation
    }
  };

  const createLink = (name, key, type) => `<a href="/${type}/${key}">${name}</a>`;

  const addLinksToContent = (content) => {
    if (typeof content !== 'string') {
      return content; // If it's not a string, just return the content as it is.
    }

    let sanitizedContent = content;
    linkedItems.current.clear();

    supplements.forEach(supplement => {
      if (supplement.key !== itemKey && !linkedItems.current.has(supplement.key)) {
        const regex = new RegExp(`\\b${supplement.name}\\b`, 'i');
        sanitizedContent = sanitizedContent.replace(regex, match => createLink(match, supplement.key, 'supplements'));
        linkedItems.current.add(supplement.key);
      }
    });

    conditions.forEach(condition => {
      if (condition.key !== itemKey && !linkedItems.current.has(condition.key)) {
        const regex = new RegExp(`\\b${condition.name}\\b`, 'i');
        sanitizedContent = sanitizedContent.replace(regex, match => createLink(match, condition.key, 'conditions'));
        linkedItems.current.add(condition.key);
      }
    });

    return sanitizedContent;
  };

  const renderContentWithLinks = (content) => {
    if (Array.isArray(content)) {
      return (
        <ul>
          {content.map((item, index) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addLinksToContent(item)) }} />
          ))}
        </ul>
      );
    }
    return <Box dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addLinksToContent(content)) }} />;
  };

  const renderCardContent = (card) => (
    <Typography component="div">{renderContentWithLinks(card.content)}</Typography>
  );

  function FixTitle({ card, detailKey }) {
    const title = card[detailKey];
    const textToShow = title === "Supplements that can help"
      ? "SUPPLEMENTS THAT MAY HELP"
      : title.toUpperCase();
    return (
      <Typography variant="h6" className="card-heading">
        {textToShow}
      </Typography>
    );
  }

  // Function to generate meta description from overview
  const generateDescription = () => {
    if (!selectedItem?.cards) return '';

    // Find the overview card
    const overviewCard = selectedItem.cards.find(
      card => card[detailKey]?.toLowerCase() === 'overview'
    );

    if (overviewCard?.content) {
      // Clean the content (remove HTML tags)
      const cleanContent = overviewCard.content.replace(/<[^>]*>/g, '');
      
      // Get first 155 characters, try to end at a complete sentence
      let description = cleanContent.substring(0, 155);
      const lastPeriod = description.lastIndexOf('.');
      
      if (lastPeriod > 120) { // Only trim if we have a decent length
        description = description.substring(0, lastPeriod + 1);
      } else {
        // Add ellipsis if we truncated mid-sentence
        description = description.trim() + '...';
      }
      
      return description;
    }

    // Fallback description if no overview found
    return `Learn about ${itemName} ${dataKey === 'supplements' ? 
      'supplement benefits, dosage, and side effects' : 
      'symptoms, treatments, and related conditions'}`;
  };

  const generateStructuredData = () => {
    if (!selectedItem || !itemName) return null;

    const baseStructure = {
      "@context": "https://schema.org",
      "@type": "Article",  // Changed from WebPage for better content representation
      "headline": `${itemName} | myStack`,
      "name": itemName,
      "description": generateDescription(),
      "url": `${window.location.origin}/${dataKey}/${itemKey}`,
      "publisher": {
        "@type": "Organization",
        "name": "myStack"
      },
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": `${window.location.origin}/${dataKey}/${itemKey}`
      },
      "dateModified": new Date().toISOString(),
      "breadcrumb": {
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "name": dataKey === 'supplements' ? "Supplements" : "Conditions",
            "item": `${window.location.origin}/${dataKey}`
          },
          {
            "@type": "ListItem",
            "position": 2,
            "name": itemName,
            "item": `${window.location.origin}/${dataKey}/${itemKey}`
          }
        ]
      }
    };

    return baseStructure;
  };

  return (
    <>
      <SEO
        title={itemName}
        description={generateDescription()}
        type={dataKey === 'supplements' ? 'product' : 'article'}
        canonicalUrl={`${window.location.origin}/${dataKey}/${itemKey}`}
        structuredData={generateStructuredData()}
      />
      <Box sx={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: { xs: 2, md: 4 }
      }}>
        {/* Header section - always centered */}
        <Box sx={{ 
          width: '100%',
          maxWidth: '1200px',  // Max width for the entire component
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          marginBottom: 4
        }}>
          <Typography variant="h4" className="item-heading" gutterBottom>
            {itemName}
          </Typography>

          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label="Details" />
            <Tab label="Recent Research" />
          </Tabs>
        </Box>

        {/* Content section - centered with max-width */}
        <Box sx={{ 
          width: '100%',
          maxWidth: '1200px',  // Match parent max-width
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          {activeTab === 0 && selectedItem && (
            <Box className="detail-content" sx={{ 
              width: '100%',
              maxWidth: '1000px'  // Content max-width
            }}>
              {selectedItem.cards.map((card, index) => (
                <Paper key={`${detailKey}-${index}`} sx={{ padding: 2, marginBottom: 2 }}>
                  <FixTitle card={card} detailKey={detailKey} />
                  {renderCardContent(card)}
                </Paper>
              ))}
            </Box>
          )}

          {activeTab === 1 && !articleId && (
            <Box sx={{ 
              width: '100%',
              maxWidth: '1000px',  // Keep the max-width for larger screens
              px: { xs: 0, sm: 2 }  // Remove padding on mobile, add it back for tablet+
            }}>
              <Research />
            </Box>
          )}

          {articleId && <ArticleDetail />}
        </Box>
      </Box>
    </>
  );
}

export default Detail;
