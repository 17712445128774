import React from 'react';
import { Box, CircularProgress } from '@mui/material';

function LoadingScreen() {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: '100vh',
        backgroundColor: 'background.default'
      }}
    >
      <CircularProgress color="primary" />
    </Box>
  );
}

export default LoadingScreen; 