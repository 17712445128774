import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Search from './Search';
import Detail from './Detail';
import { Box } from '@mui/material';

function Conditions() {
  const { conditionKey } = useParams();
  const [searchFocused, setSearchFocused] = useState(false);

  useEffect(() => {
    setSearchFocused(!!conditionKey);
  }, [conditionKey]);

  const handleLoadingComplete = () => {
    setSearchFocused(false);
  };

  return (
    <Box 
      sx={{ 
        width: '100%',
        minHeight: conditionKey ? 'auto' : '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: conditionKey ? 3 : 0,
        backgroundColor: 'background.default'
      }}
    >
      <Box 
        sx={{ 
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: conditionKey ? 'relative' : 'fixed',
          top: conditionKey ? 'auto' : { xs: '20%', sm: '30%' },
          left: conditionKey ? 'auto' : '50%',
          transform: conditionKey ? 'none' : 'translateX(-50%)',
          zIndex: 1,
        }}
      >
        <Search 
          itemKey={conditionKey}
          dataKey="conditions"
          detailKey="card"
          routeBase="conditions"
          label="Search conditions"
        />
      </Box>

      {conditionKey && (
        <Box sx={{ width: '100%', mt: 1 }}>
          <Detail 
            dataKey="conditions"
            detailKey="card"
            onLoadingComplete={handleLoadingComplete}
          />
        </Box>
      )}
    </Box>
  );
}

export default Conditions;
