import React, { useEffect, useState } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import Search from './Search';
import Detail from './Detail';
import { Card, Box } from '@mui/material';

function Supplements() {
  const { supplementKey } = useParams();
  const [searchFocused, setSearchFocused] = useState(false);

  useEffect(() => {
    setSearchFocused(!!supplementKey);
  }, [supplementKey]);

  const handleLoadingComplete = () => {
    setSearchFocused(false);
  };

  return (
    <Box 
      sx={{ 
        width: '100%',
        minHeight: supplementKey ? 'auto' : '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: supplementKey ? 3 : 0,
        backgroundColor: 'background.default'
      }}
    >
      <Box 
        sx={{ 
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          position: supplementKey ? 'relative' : 'fixed',
          top: supplementKey ? 'auto' : { xs: '20%', sm: '30%' },
          left: supplementKey ? 'auto' : '50%',
          transform: supplementKey ? 'none' : 'translateX(-50%)',
          zIndex: 1,
        }}
      >
        <Search 
          itemKey={supplementKey}
          dataKey="supplements"
          detailKey="card"
          routeBase="supplements"
          label="Search supplements"
        />
      </Box>

      {supplementKey && (
        <Box sx={{ width: '100%', mt: 1 }}>
          <Detail 
            dataKey="supplements"
            detailKey="card"
            onLoadingComplete={handleLoadingComplete}
          />
        </Box>
      )}
    </Box>
  );
}

export default Supplements;
