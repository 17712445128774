// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, Menu, MenuItem, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import './Header.css';
import { initializeGoogleSignIn } from './auth/AuthUtils';



function Header() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [message, setMessage] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    // Initialize Google Sign-in handler
    initializeGoogleSignIn(navigate, setMessage);
  }, [navigate]);

  // Define a global function to check if the user is logged in
  window.checkUserLoggedIn = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      // User is logged in, return true
      return JSON.stringify({ isLoggedIn: true });
    } else {
      // User is not logged in, return false
      return JSON.stringify({ isLoggedIn: false });
    }
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogin = () => {
    logout();
    handleClose();
    navigate('/home');
  };

  const handleLogout = () => {
    logout();
    handleClose();
    navigate('/');
  };

  const handleProfile = () => {
    handleClose();
    navigate('/profile');
  };

  const handleSupport = () => {
    handleClose();
    navigate('/contact-us');
  };

  const handleStacksClick = () => {
    if (currentUser) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >



      <List>

      {currentUser && (
          <>
            <ListItem button onClick={handleProfile}>
              <ListItemText primary="Profile" />
            </ListItem>
            <ListItem button onClick={handleSupport}> {/* Add support link */}
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        )}


        {!currentUser && (
          <>
            <ListItem button onClick={handleLogin}>
                <ListItemText>Login</ListItemText> 
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
              {drawer}
            </Drawer>
            <Typography
              variant="h5"
              style={{ 
                flexGrow: 1, 
                cursor: 'pointer', 
                fontFamily: 'Lora, serif',  // Slightly cursive, yet modern font
                fontWeight: 700,  // Medium weight for balance and readability
                fontSize: '28px',  // Adjust based on logo size
                letterSpacing: '0.2px',  // Slight spacing for elegance
                color: '#CCDDAA',  // Fresh green color symbolizing health
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',  // Soft shadow for subtle depth
                padding: '0 10px',  // Adds space around the text
                borderRadius: '15px',  // Soft rounded edges to keep it approachable
                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)'  // Soft shadow for 3D effect
              }}
              onClick={handleStacksClick}
            > 
              myStack
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="h5"
              style={{ 
                flexGrow: 1, 
                cursor: 'pointer', 
                fontFamily: 'Lora, serif',  // Slightly cursive, yet modern font
                fontWeight: 700,  // Medium weight for balance and readability
                fontSize: '28px',  // Adjust based on logo size
                letterSpacing: '0.1px',  // Slight spacing for elegance
                color: '#CCDDAA',  
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.1)',  // Soft shadow for subtle depth
                padding: '0 10px',  // Adds space around the text
                borderRadius: '15px',  // Soft rounded edges to keep it approachable
                boxShadow: '0 3px 5px rgba(0, 0, 0, 0.1)'  // Soft shadow for 3D effect
              }}
             
              onClick={handleStacksClick}
            >
              myStack
            </Typography>
            <Button color="inherit" href="/conditions">Conditions</Button>
            <Button color="inherit" href="/supplements">Supplements</Button>
            {currentUser ? (
          <>
            <Button color="inherit" onClick={handleLogout}>Logout</Button>
          </>
        ) : (
          <Button color="inherit" onClick={() => navigate('/login')}>Login</Button>
        )}
            {currentUser && (
              <>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleProfile}>Profile</MenuItem>
                  <MenuItem onClick={handleSupport}>Contact Us</MenuItem> {/* Add support link */}
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;