import { colors } from '../../theme';

export const buttonStyles = {
    primary: {
      height: '40px',
      borderRadius: '10px',
      textTransform: 'none',
      fontSize: '0.875rem',
      background: theme => theme.gradients.primary,
      boxShadow: theme => theme.customShadows.button,
      '&:hover': {
        background: theme => theme.gradients.hover,
        boxShadow: theme => theme.customShadows.buttonHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.grey.border,
      }
    },
    secondary: {
      height: '40px',
      borderRadius: '10px',
      textTransform: 'none',
      borderColor: colors.grey.border,
      color: colors.green.medium,
      '&:hover': {
        borderColor: colors.green.medium,
        backgroundColor: colors.green.transparent.medium,
        boxShadow: theme => theme.customShadows.button,
      }
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 1
    },
    auth: {
      mt: 2,
      mb: 2,
      height: '45px',
      minHeight: '45px',
      py: 1,
      px: 3,
      backgroundColor: '#435334',
      opacity: 0.9,
      color: '#fff',
      borderRadius: '12px',
      textTransform: 'none',
      fontSize: '0.9rem',
      fontWeight: 600,
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: '#435334',
        opacity: 1,
        transform: 'translateY(-1px)',
        boxShadow: '0 4px 12px rgba(67, 83, 52, 0.2)'
      },
      '&:active': {
        transform: 'translateY(0)',
      }
    }
  };