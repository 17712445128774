import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Paper, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import { buttonStyles } from './common/ButtonStyles';
import { authStyles } from './common/AuthStyles';
import { getAnalytics, logEvent } from 'firebase/analytics';

const categories = [
  { value: 'give_suggestions', label: 'Give Suggestions' },
  { value: 'report_issues', label: 'Report Issues' },
  { value: 'request_information', label: 'Request Information' },
  { value: 'other', label: 'Other' },
];

const ContactUs = () => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [category, setCategory] = useState('');
  const [feedback, setFeedback] = useState('');
  const [message, setMessage] = useState('');
  const [showDebug, setShowDebug] = useState(false);
  const [debugClicks, setDebugClicks] = useState(0);

  useEffect(() => {
    // Log to analytics
    const analytics = getAnalytics();
    logEvent(analytics, 'user_agent_check', {
      user_agent: window.navigator.userAgent,
      platform: window.navigator.platform,
      is_mobile: /Mobile|Android|iPhone/i.test(window.navigator.userAgent)
    });
    
    // Log to console as well
    console.log('Debug Info:', {
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform,
      vendor: window.navigator.vendor
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!feedback || !category) {
      setMessage('All fields are required');
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        await addDoc(collection(db, 'support'), {
          userId: user.uid,
          email: user.email,
          firstName: user.displayName?.split(' ')[0] || '',
          lastName: user.displayName?.split(' ')[1] || '',
          category,
          feedback,
          timestamp: new Date(),
        });
        setMessage('Your message has been submitted successfully');
        setFeedback('');
        setCategory('');
        setTimeout(() => {
          navigate('/');
        }, 2000); // Redirect to home after 2 seconds
      } else {
        setMessage('You need to be logged in to submit');
      }
    } catch (error) {
      setMessage(`Error submitting: ${error.message}`);
    }
  };

  const handleDebugClick = () => {
    const newClicks = debugClicks + 1;
    console.log('Debug clicks:', newClicks); // Debug log
    setDebugClicks(newClicks);
    if (newClicks >= 3) { // Reduced to 3 clicks
      setShowDebug(true);
      console.log('Debug mode activated'); // Debug log
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper 
        elevation={3} 
        sx={{ 
          p: { xs: 2, sm: 3 },
          mt: { xs: 2, sm: 8 },
          borderRadius: '16px',
          border: '1px solid rgba(158, 179, 132, 0.2)',
          background: '#fff',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)'
        }}
      >
        <Typography 
          component="h1" 
          variant="h5" 
          gutterBottom
          onClick={handleDebugClick}
          sx={{
            color: '#435334',
            fontWeight: 600,
            fontSize: { xs: '1.5rem', sm: '1.75rem' },
            mb: 3,
            textAlign: 'center',
            cursor: 'pointer'
          }}
        >
          Contact Us
        </Typography>

        {message && (
          <Typography 
            sx={{
              ...authStyles.message,
              color: message.includes('successfully') ? '#4caf50' : '#d32f2f',
              textAlign: 'center',
              mb: 2
            }}
          >
            {message}
          </Typography>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="category-label">Category</InputLabel>
            <Select
              labelId="category-label"
              id="category"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              label="Category"
              sx={{
                borderRadius: '12px',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(67, 83, 52, 0.2)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(67, 83, 52, 0.3)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#435334',
                }
              }}
            >
              {categories.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="feedback"
            label="Your Feedback"
            name="feedback"
            autoComplete="feedback"
            multiline
            rows={8}
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            sx={{
              mt: 2,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                '& fieldset': {
                  borderColor: 'rgba(67, 83, 52, 0.2)',
                },
                '&:hover fieldset': {
                  borderColor: 'rgba(67, 83, 52, 0.3)',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#435334',
                }
              }
            }}
          />

          <Box sx={{ mt: 3, mb: 2 }}>
            <Button
              type="submit"
              fullWidth
              sx={buttonStyles.auth}
            >
              Submit Feedback
            </Button>
          </Box>
        </Box>

        {/* Debug section */}
        <Box 
          sx={{ 
            mt: 4, 
            p: 2, 
            display: showDebug ? 'block' : 'none',
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            fontSize: '12px',
            wordBreak: 'break-all'
          }}
        >
          <Typography variant="caption" component="div" sx={{ fontWeight: 'bold' }}>
            Debug Information (Click Count: {debugClicks})
          </Typography>
          <pre style={{ whiteSpace: 'pre-wrap' }}>
            UserAgent: {window.navigator.userAgent}
            {'\n'}
            Platform: {window.navigator.platform}
            {'\n'}
            Vendor: {window.navigator.vendor}
            {'\n'}
            Mobile: {/Mobile|Android|iPhone/i.test(window.navigator.userAgent).toString()}
            {'\n'}
            Screen: {window.innerWidth}x{window.innerHeight}
          </pre>
        </Box>
      </Paper>
    </Container>
  );
};

export default ContactUs;