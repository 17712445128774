import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, signInWithCredential, sendPasswordResetEmail } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Container, TextField, Button, Typography, Box, Paper, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { authStyles } from '../common/AuthStyles';
import './GoogleButton.css';  // We'll move the Google button styles to a separate file
import { buttonStyles } from '../common/ButtonStyles';
import { handleFirebaseSignIn, initializeGoogleSignIn } from './AuthUtils';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [message, setMessage] = useState('');
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  
  const navigate = useNavigate();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  useEffect(() => {
    // Initialize Google Sign-in handler
    initializeGoogleSignIn(navigate, setMessage);
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    if (!email || !password) {
      setMessage('Email and password are required');
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

/*     Enable this to require email verification before logging in.
      if (!user.emailVerified) {
        setMessage('Please verify your email before logging in.');
        auth.signOut();
        navigate('/verify-email');
        return;
      } */

      setMessage('Login successful!');
      navigate('/dashboard');
    } catch (error) {
      setMessage(`Login failed: ${error.message}`);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!resetEmail) {
      setResetMessage('Email is required for password reset');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      setResetMessage(`Failed to send reset email: ${error.message}`);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      
      // Get the correct credential using credentialFromResult
      const credential = GoogleAuthProvider.credentialFromResult(result);
      
      // Pass the credential to handleFirebaseSignIn
      await handleFirebaseSignIn(credential, navigate, setMessage);
    } catch (error) {
      console.error('Google sign-in error:', error);
      setMessage('Unable to sign in with Google. Please try again.');
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={0} sx={authStyles.paper}>
        <Typography component="h1" variant="h4" sx={authStyles.title}>
          {isResetPassword ? 'Reset Password' : 'Sign In'}
        </Typography>

        {isResetPassword ? (
          // Reset Password Form
          <Box component="form" onSubmit={handleResetPassword} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="resetEmail"
              label="Email Address"
              name="resetEmail"
              autoComplete="email"
              autoFocus
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              sx={authStyles.textField}
            />
            
            <Button
              type="submit"
              fullWidth
              sx={authStyles.submitButton}
            >
              Send Reset Link
            </Button>

            {resetMessage && (
              <Typography sx={authStyles.message} color={resetMessage.includes('failed') ? 'error' : 'success'}>
                {resetMessage}
              </Typography>
            )}

            <Button
              fullWidth
              onClick={() => {
                setIsResetPassword(false);
                setResetEmail('');
                setResetMessage('');
              }}
              sx={{
                ...authStyles.secondaryButton,
                mt: 2
              }}
            >
              Back to Sign In
            </Button>
          </Box>
        ) : (
          // Regular Sign In Form
          <>
            <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                value={formData.email}
                onChange={handleChange}
                sx={authStyles.textField}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formData.password}
                onChange={handleChange}
                sx={authStyles.textField}
              />
              
              <Link
                component="button"
                variant="body2"
                onClick={(e) => {
                  e.preventDefault();
                  setIsResetPassword(true);
                  setMessage('');
                }}
                sx={{ display: 'block', marginTop: 1, textAlign: 'right' }}
              >
                Forgot Password?
              </Link>

              <Button
                type="submit"
                fullWidth
                sx={buttonStyles.auth}
              >
                Sign in with Email
              </Button>

              {message && (
                <Typography sx={authStyles.errorMessage}>
                  {message}
                </Typography>
              )}
            </Box>

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              my: 3
            }}>
              <Box sx={{ flex: 1, borderBottom: '1px solid #e0e0e0' }} />
              <Typography variant="body1" sx={{ px: 2, color: 'text.secondary' }}>
                or
              </Typography>
              <Box sx={{ flex: 1, borderBottom: '1px solid #e0e0e0' }} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
              <button
                className="gsi-material-button"
                onClick={handleGoogleSignIn}
                style={{ border: 'none', padding: 0, background: 'none' }}
              >
                <div className="gsi-material-button-state"></div>
                <div className="gsi-material-button-content-wrapper">
                  <div className="gsi-material-button-icon">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
                      <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                      <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                      <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                      <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                      <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                  </div>
                  <span className="gsi-material-button-contents">Continue with Google</span>
                  <span style={{ display: 'none' }}>Continue with Google</span>
                </div>
              </button>
            </Box>

            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              my: 3
            }}>
              <Box sx={{ flex: 1, borderBottom: '1px solid #e0e0e0' }} />
              <Typography variant="body1" sx={{ px: 2, color: 'text.secondary' }}>
                New to myStack?
              </Typography>
              <Box sx={{ flex: 1, borderBottom: '1px solid #e0e0e0' }} />
            </Box>

            <Button
              fullWidth
              onClick={() => navigate('/register')}
              sx={{
                ...authStyles.secondaryButton,
                border: '2px solid',
                borderColor: 'primary.main',
                color: 'primary.main',
                fontWeight: 600,
                '&:hover': {
                  backgroundColor: 'primary.main',
                  color: 'white',
                  border: '2px solid',
                  borderColor: 'primary.main',
                }
              }}
            >
              Create an Account
            </Button>

            <Typography variant="body2" align="center" sx={{ mt: 2 }}>
              By continuing you are agreeing to the{' '}
              <Link href="/terms-of-use" target="_blank">Terms of Use</Link> and the{' '}
              <Link href="/privacy-policy" target="_blank">Privacy Policy</Link>.
            </Typography>
          </>
        )}
      </Paper>
    </Container>
  );
}

export default Login; 