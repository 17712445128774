import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, collection, addDoc } from 'firebase/firestore';
import { Container, Typography, Box, Card, CardContent, Table, TableBody, TableCell, TableRow, TableContainer, TableHead, CircularProgress, Button, Link } from '@mui/material';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { db } from '../firebase';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';
import { OpenInNew as OpenInNewIcon } from '@mui/icons-material';

function ViewStack() {
  const { stackId } = useParams();
  const [stack, setStack] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    fetchStackData();
  }, [stackId]);

  async function fetchStackData() {
    try {
      
      const stackDoc = await getDoc(doc(db, 'stacks', stackId));
      if (stackDoc.exists()) {
        const stackData = stackDoc.data();
        setStack(stackData);
      } else {
        setError('Unable to retrieve stack');
      }
    } catch (error) {
      console.error('Error fetching stack data:', error);
      setError('Unable to retrieve stack');
    }
  }

  const handleAddToMyStack = async () => {
    setLoading(true);
    setError('');
    try {
      const user = auth.currentUser;

      if (!user) {
        setError('You need to be logged in to add a stack to your dashboard. Redirecting to login page...');

        // If the user is not logged in, introduce a 3-second delay before redirecting to the login page
        setTimeout(() => {
          navigate('/login', { state: { from: `/view-stack/${stackId}` } });
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        if (stack) {
          if (stack.userId === user.uid) {
            setError('This stack was created by you.');
          } else {
            const newStack = {
              ...stack,
              userId: user.uid,
              name: `${stack.name}`,
              createdBy: user.displayName || user.email || 'unknown',
              isPublic: false,
              origin: 'shared',
              healthGoalsRef: null,
              notesRef: null
            };

            const docRef = await addDoc(collection(db, 'stacks'), newStack);
            
            setError('Stack added to your dashboard!');
          }
        }
      }
    } catch (error) {
      console.error('Error adding stack:', error);
      setError('Failed to add stack to your dashboard.');
    }
    setLoading(false);
  };

  if (error && !stack) {
    return (
      <Container component="main" maxWidth="sm" sx={{ mt: 8, mb:20 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!stack) {
    return (
      <Container component="main" maxWidth="sm" sx={{ mt: 8 }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <>
      <Helmet>
        <title>{stack.name} - Stack Details</title>
        <meta property="og:title" content={stack.name} />
        <meta property="og:description" content="View the details of this supplement stack." />
        <meta property="og:image" content="URL_TO_IMAGE" />
        <meta property="og:url" content={`YOUR_APP_URL/view-stack/${stackId}`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <Container component="main" maxWidth="md" sx={{ mt: 8 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography component="h1" variant="h4" sx={{ mb: 4 }}>
            {stack.name}
          </Typography>
          <Card sx={{ boxShadow: 3, width: '100%', mb: 4 }}>
            <CardContent>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h6">Details</Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Supplement</TableCell>
                        <TableCell>Dosage</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stack.supplements.map((supplement, index) => (
                        <TableRow key={index}>
                          <TableCell>
                            {supplement.supplementId ? (
                              <a 
                                href={`/supplements/${supplement.supplementId}`} 
                                target="_blank" 
                                rel="noopener noreferrer"
                                style={{ color: theme.palette.primary.main, textDecoration: 'underline' }}
                              >
                                {supplement.name}
                              </a>
                            ) : (
                              supplement.name
                            )}
                          </TableCell>
                          <TableCell>
                            {`${supplement.dosage} ${supplement.unit}`}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </CardContent>
          </Card>
          {/* <Button variant="contained" color="primary" onClick={handleAddToMyStack} disabled={loading}>
            {loading ? 'Adding...' : 'Add to my dashboard'}
          </Button> */}
          <Link 
            href="/create-stack" 
            target="_blank" 
            rel="noopener noreferrer" 
            sx={{ mt: 1, mb: 20, color: theme.palette.primary.main, textDecoration: 'underline', fontSize: '0.94rem' }}
          >
            Create a stack using myStack AI <OpenInNewIcon fontSize="small" />
          </Link>
          {error && (
            <Typography variant="body2" color="error" sx={{ mt: 2, mb: 30 }}>
              {error}
            </Typography>
          )}
        </Box>
      </Container>
    </>
  );
}

export default ViewStack;