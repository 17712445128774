import { getAuth, signInWithCredential, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';

export const handleFirebaseSignIn = async (credential, navigate, setMessage) => {
  try {
    const auth = getAuth();
    const result = await signInWithCredential(auth, credential);
    const user = result.user;

    const userDoc = doc(db, 'users', user.uid);
    const docSnap = await getDoc(userDoc);

    if (!docSnap.exists()) {
      let firstName = 'User';
      let lastName = '';
      
      if (user.displayName) {
        const nameParts = user.displayName.split(' ');
        firstName = nameParts[0] || 'User';
        lastName = nameParts.slice(1).join(' ') || '';
      }

      await setDoc(userDoc, {
        email: user.email || '',
        firstName,
        lastName,
        dateOfBirth: '',
        gender: '',
        createdAt: new Date().toISOString()
      });
    }

    const userData = docSnap.data();
    if (!userData || !userData.dateOfBirth) {
      sessionStorage.setItem('profileMessage', 
        'Please update your birth month and year to continue. You must be 18 or older to use myStack. ' +
        'This information also helps us provide personalized supplement recommendations tailored to your age.');
      navigate('/profile');
    } else {
      navigate('/dashboard');
    }
    
    return true;
  } catch (error) {
    console.error('Firebase sign-in error:', error);
    if (setMessage) {
      setMessage(`Sign-in failed: ${error.message}`);
    }
    return false;
  }
};

export const initializeGoogleSignIn = (navigate, setMessage) => {
  window.handleGoogleToken = async function(token) {
    try {
      console.log('Received Google token, attempting sign in...'); // Debug log
      const credential = GoogleAuthProvider.credential(token);
      await handleFirebaseSignIn(credential, navigate, setMessage);
    } catch (error) {
      console.error('Google sign-in error:', error);
      if (setMessage) {
        setMessage(`Google sign-in failed: ${error.message}`);
      }
    }
  };
}; 