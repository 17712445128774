import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Typography,
  Paper,
  Grid,
  Divider,
  Chip,
  CircularProgress,
  Box,
  Link,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import config from '../config';
import './Detail.css';
import SEO from './common/SEO';

const ArticleDetail = () => {
  const { articleId, supplementKey, conditionKey } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchArticle();
    window.scrollTo(0, 0);
  }, [articleId]);

  // Function to fetch a single article by article_ref using Edge Function
  const fetchArticle = async () => {
    setLoading(true);
    setError(null);
    try {
      const queryParams = new URLSearchParams({
        mode: 'single',
        article_ref: articleId
      });

      const response = await fetch(
        `${config.EDGE_FUNCTION_URL}/getResearchArticles?${queryParams}`
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 429) {
          throw new Error('You\'ve reached your daily reading limit. Consider creating an account for unlimited access to our research database.');
        }
        throw new Error('Unable to load article. Please try again later.');
      }

      const result = await response.json();

      // Check if we have data and it's the first item in the array
      if (result.data && Array.isArray(result.data) && result.data.length > 0) {
        setArticle(result.data[0]);
      } else if (result.data && !Array.isArray(result.data)) {
        // If data is a single object
        setArticle(result.data);
      } else {
        throw new Error('Article not found');
      }

    } catch (error) {
      console.error('Error fetching article:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Generate structured data for scholarly article
  const generateStructuredData = () => {
    if (!article) return null;

    // Validate required fields
    const headline = article.ai_title_simplified || article.title;
    if (!headline) return null;

    const structuredData = {
      "@context": "https://schema.org",
      "@type": "ScholarlyArticle",
      "headline": `${headline} | myStack Research`,
      "name": headline,
      "datePublished": article.date_published || new Date().toISOString(),
      "publisher": {
        "@type": "Organization",
        "name": article.journal_title || "myStack"
      },
      "description": generateDescription(),
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": `${window.location.origin}/${supplementKey ? 'supplements' : 'conditions'}/${supplementKey || conditionKey}/research/${articleId}`
      }
    };

    // Add author if available
    if (article.first_author_name) {
      structuredData.author = [{
        "@type": "Person",
        "name": article.first_author_name,
        ...(article.first_author_affiliation && {
          "affiliation": {
            "@type": "Organization",
            "name": article.first_author_affiliation
          }
        })
      }];
    }

    // Add keywords if available
    if (Array.isArray(article.ai_effects)) {
      structuredData.keywords = article.ai_effects.join(", ");
    }

    // Add subject/condition if available
    if (article.ai_subject_condition) {
      structuredData.about = [{
        "@type": "MedicalCondition",
        "name": article.ai_subject_condition
      }];
    }

    return structuredData;
  };

  // Generate meta description
  const generateDescription = () => {
    if (!article) return '';
    
    // Use AI summary if available, otherwise use start of abstract
    const description = article.ai_abstract_summary || article.abstract;
    if (!description) return '';

    // Clean and truncate description
    const cleanDescription = description.replace(/<[^>]*>/g, '');
    let truncated = cleanDescription.substring(0, 155);
    const lastPeriod = truncated.lastIndexOf('.');
    
    if (lastPeriod > 120) {
      truncated = truncated.substring(0, lastPeriod + 1);
    } else {
      truncated = truncated.trim() + '...';
    }
    
    return truncated;
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    const isRateLimit = error.includes('daily');
    return (
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        marginTop: 4,
        padding: 3
      }}>
        <Paper 
          elevation={0}
          sx={{ 
            padding: 4, 
            textAlign: 'center',
            maxWidth: 600,
            backgroundColor: '#f5f5f5'
          }}
        >
          <Typography variant="h6" gutterBottom color="text.primary">
            {isRateLimit ? 'Reading Limit Reached' : 'Something went wrong'}
          </Typography>
          <Typography color="text.secondary" paragraph>
            {error}
          </Typography>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: isRateLimit ? 'column' : 'row',
            gap: 2,
            justifyContent: 'center',
            marginTop: 2 
          }}>
            {isRateLimit && (
              <RouterLink
                to="/login"
                style={{ textDecoration: 'none' }}
              >
                <Link
                  component="button"
                  sx={{
                    backgroundColor: 'primary.main',
                    color: 'white',
                    padding: '8px 16px',
                    borderRadius: 1,
                    textDecoration: 'none',
                    '&:hover': {
                      backgroundColor: 'primary.dark',
                    },
                  }}
                >
                  Log In or Create Account
                </Link>
              </RouterLink>
            )}
            <Link
              component="button"
              onClick={() => navigate(-1)}
              sx={{
                color: 'primary.main',
                textDecoration: 'none',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              Back to Research Articles
            </Link>
          </Box>
        </Paper>
      </Box>
    );
  }

  if (!article) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 4 }}>
        <Typography>Article not found.</Typography>
        <Link
          component="button"
          onClick={() => navigate(-1)}
          sx={{
            color: 'primary.main',
            textDecoration: 'none',
            marginTop: 2,
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Go Back
        </Link>
      </Box>
    );
  }

  // Navigate back to the research articles page
  const handleBackToResearch = () => {
    navigate(`/${supplementKey ? 'supplements' : 'conditions'}/${supplementKey || conditionKey}/research`);
  };

  // Helper function to render list of chips
  const renderChips = (items = []) => {
    if (!Array.isArray(items)) return null;
    return items.map((item, index) => (
      <Chip
        key={index}
        label={item}
        variant="outlined"
        sx={{ marginRight: 0.5, marginBottom: 0.5 }}
      />
    ));
  };

  // Format the published date
  const formatDate = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  };

  // Expand study type abbreviation
  const getStudyType = (type) => {
    if (!type) return null;
    switch (type) {
      case 'RCT':
        return 'Randomized Controlled Trial';
      default:
        return type;
    }
  };

  return (
    <>
      <SEO
        title={article?.ai_title_simplified || article?.title}
        description={generateDescription()}
        type="article"
        canonicalUrl={`${window.location.origin}/${supplementKey ? 'supplements' : 'conditions'}/${supplementKey || conditionKey}/research/${articleId}`}
        structuredData={generateStructuredData()}
      />
      <Box sx={{ 
        paddingX: { xs: 0, md: 0 },
        maxWidth: '100%',
        margin: '0 auto'
      }}>
        {/* Back Link */}
        <Link
          component="button"
          onClick={handleBackToResearch}
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'text.secondary',
            textDecoration: 'none',
            marginBottom: 2,
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 20, marginRight: 0.5 }} />
          Back to All Research
        </Link>

        {/* Main Content */}
        <Paper
          className="card"
          sx={{
            padding: { xs: 2, md: 4 },
            maxWidth: '100%',
            width: 'auto',
            marginX: 'auto',
            marginBottom: 4,
            backgroundColor: '#ffffff',
            borderRadius: 1,
          }}
          elevation={4}
        >
          {/* Original Title */}
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: 'text.primary',
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 2,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              fontSize: { xs: '1.2rem', sm: '1.4rem' }
            }}
          >
            {article.title}
          </Typography>

          {/* Simplified Title */}
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{
              color: 'text.secondary',
              textAlign: 'center',
              fontStyle: 'italic',
              marginBottom: 4,
              wordBreak: 'break-word',
              overflowWrap: 'break-word',
              fontSize: { xs: '1rem', sm: '1.1rem' }
            }}
          >
            {article.ai_title_simplified}
          </Typography>

          {/* Combined Details */}
          <Divider sx={{ marginY: 2 }} />
          <Grid container spacing={4} sx={{ marginBottom: 4 }}>
            {/* Publication Details */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 2 }}
              >
                Publication Details
              </Typography>
              <Box>
                {formatDate(article.date_published) && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Published Date:</strong> {formatDate(article.date_published)}
                  </Typography>
                )}
                {article.journal_title && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Journal:</strong> {article.journal_title}
                  </Typography>
                )}
                {article.first_author_name && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>First Author:</strong> {article.first_author_name}
                  </Typography>
                )}
                {article.first_author_affiliation && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Affiliation:</strong> {article.first_author_affiliation}
                  </Typography>
                )}
              </Box>
            </Grid>

            {/* Study Details */}
            <Grid item xs={12} md={6}>
              <Typography
                variant="h6"
                sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 2 }}
              >
                Study Details
              </Typography>
              <Box>
                {article.ai_study_type && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Study Type:</strong> {article.ai_study_type}
                  </Typography>
                )}
                {(article.ai_number_of_subjects === 0 || article.ai_number_of_subjects !== null) && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Number of Subjects:</strong> {article.ai_number_of_subjects}
                  </Typography>
                )}
                {article.ai_subject_type && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Subject Type:</strong> {article.ai_subject_type}
                  </Typography>
                )}
                {article.ai_subject_condition && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Subject Condition:</strong> {article.ai_subject_condition}
                  </Typography>
                )}
                {article.ai_confidence_of_outcome && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Confidence of Outcome:</strong> {article.ai_confidence_of_outcome}
                  </Typography>
                )}
                {article.ai_study_country && (
                  <Typography variant="body2" sx={{ marginBottom: 1 }}>
                    <strong>Study Country:</strong> {article.ai_study_country}
                  </Typography>
                )}
              </Box>
            </Grid>
          </Grid>

          {/* Key Findings */}
          {article.ai_abstract_summary && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 2 }}
              >
                Key Findings
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ color: 'text.secondary', lineHeight: 1.8 }}
              >
                {article.ai_abstract_summary}
              </Typography>
            </>
          )}

          {/* Effects */}
          {article.ai_effects && article.ai_effects.length > 0 && (
            <>
              <Typography
                variant="h6"
                gutterBottom
                sx={{
                  color: 'text.primary',
                  fontWeight: 'bold',
                  marginTop: 4,
                  marginBottom: 2,
                }}
              >
                Notable Effects
              </Typography>
              <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {renderChips(article.ai_effects)}
              </Box>
            </>
          )}
        </Paper>

        {/* Study Abstract */}
        <Paper
          className="card"
          sx={{
            padding: { xs: 2, md: 4 },
            maxWidth: '100%',
            width: 'auto',
            marginX: 'auto',
            marginBottom: 4,
            backgroundColor: '#fafafa',
            borderRadius: 4,
          }}
          elevation={2}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ color: 'text.primary', fontWeight: 'bold', marginBottom: 2 }}
          >
            Study Abstract
          </Typography>
          <Typography
            variant="body2"
            gutterBottom
            sx={{ color: 'text.secondary', lineHeight: 1.8 }}
          >
            {article.abstract}
          </Typography>

          {/* View Full Text Button */}
          {article.link_to_full_text && (
            <Box sx={{ marginTop: 4, textAlign: 'center' }}>
              <Link
                href={article.link_to_full_text}
                target="_blank"
                rel="noopener noreferrer"
                variant="body1"
                sx={{
                  textDecoration: 'none',
                  padding: 1,
                  borderRadius: 1,
                  backgroundColor: '#1976d2',
                  color: '#fff',
                  '&:hover': {
                    backgroundColor: '#115293',
                  },
                }}
              >
                View Full Text
              </Link>
            </Box>
          )}
        </Paper>
      </Box>
    </>
  );
};

export default ArticleDetail;
