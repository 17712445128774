import React from 'react';
import { 
  TextField, 
  Box, 
  Button, 
  Typography, 
  Chip, 
  InputAdornment, 
  IconButton, 
  CircularProgress, 
  Alert, 
  Collapse, 
  Switch,
  Tooltip
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { buttonStyles } from './ButtonStyles';
import { useState } from 'react';
import { keyframes } from '@mui/material/styles';

const sampleGoals = [
  'Weight Management',
  'Muscle Health',
  'Digestion',
  'Energy',
  'Sleep Hygiene',
  'Heart Health',
  'Focus',
  'Stress Relief',
  'Immunity',
  'GLP-1 Agonist nutrional augmentation',
  'Healthy Hair & Skin',
  'Healthy Aging',
  'Memory',
  'Joint Health',
  'Bone Health',
  'Hormone Balance',
  'Prostate Health',
  'Eye Health',
  'Prenatal & Postnatal',
  'Healthy Mood',
  'Gluclose Metabolism'
];

const shimmer = keyframes`
  0% { background-position: -200% 0; }
  100% { background-position: 200% 0; }
`;

const disabledStripes = keyframes`
  from { background-position: 0 0; }
  to { background-position: 10px 0; }
`;

export default function StackCreationForm({ 
  healthGoals, 
  setHealthGoals, 
  onSubmit, 
  loading = false,
  variant = 'full', // 'full' or 'landing'
  showHeader = true // Add this prop to control header visibility
}) {
  const [showError, setShowError] = useState(false);

  const handleSampleGoalClick = (goal) => {
    const currentGoals = healthGoals ? healthGoals.split(',').map(g => g.trim()) : [];
    if (!currentGoals.includes(goal)) {
      const newGoals = [...currentGoals, goal].join(', ');
      setHealthGoals(newGoals);
    }
  };

  const handleButtonClick = () => {
    if (!healthGoals.trim()) {
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    } else {
      setShowError(false);
      onSubmit();
    }
  };

  return (
    <Box sx={{ 
      width: '100%', 
      maxWidth: '500px',
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: -2,
        left: -2,
        right: -2,
        bottom: -2,
        background: 'linear-gradient(135deg, #9EB384 0%, #435334 100%)',
        opacity: 0.5,
        borderRadius: '22px',
        filter: 'blur(8px)',
        transition: 'opacity 0.3s ease',
      },
      '&:hover::before': {
        opacity: 0.7,
      }
    }}>
      <Box sx={{ 
        position: 'relative', 
        zIndex: 1,
        p: 3,
        backgroundColor: '#fff',
        borderRadius: '20px',
      }}>
        {showHeader && (
          <>
            <Typography
              component="h1"
              variant="h5"
              sx={{ 
                fontFamily: 'Lora, serif',
                fontWeight: 700,
                fontSize: '24px',
                letterSpacing: '0.1px',
                background: 'linear-gradient(90deg, #9EB384 15%, #435334 100%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textShadow: '0 1px 2px rgba(0,0,0,0.1)',
                padding: '4px 0',
                mb: 0,
                textAlign: 'center',
                transition: 'transform 0.2s ease',
                '&:hover': {
                  transform: 'translateY(-1px)',
                }
              }}
            >
              myStack AI
            </Typography>
            
            <Typography 
              variant="body2"
              color="text.secondary"
              textAlign="center"
              sx={{ 
                maxWidth: '400px',
                lineHeight: 1.5,
                mb: 2,
                mx: 'auto'
              }}
            >
              Let myStack AI analyze your health goals and recommend a personalized supplement stack
            </Typography>
          </>
        )}

        <TextField
          fullWidth
          multiline
          rows={2}
          placeholder="What are your health goals? Type or select from quick adds..."
          value={healthGoals}
          onChange={(e) => setHealthGoals(e.target.value)}
          variant="outlined"
          size="small"
          sx={{
            '& .MuiOutlinedInput-root': {
              borderRadius: '12px',
              backgroundColor: '#F7FAFC',
              fontSize: '0.95rem',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: '#EDF2F7',
                transform: 'translateY(-1px)',
              },
              '&.Mui-focused': {
                backgroundColor: '#F7FAFC',
                boxShadow: '0 0 0 2px rgba(158, 179, 132, 0.3)',
                transform: 'translateY(-1px)',
              }
            }
          }}
          InputProps={{
            endAdornment: healthGoals && (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setHealthGoals('')}
                  edge="end"
                  size="small"
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        
        <Box sx={{ mt: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            mb: 1
          }}>
            <Typography variant="caption" color="text.secondary">
              Quick adds:
            </Typography>

            {/* Research Toggle */}
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              position: 'relative',
              gap: 1
            }}>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ fontWeight: 500 }}
              >
                Use recent research
              </Typography>
              <Tooltip 
                title={
                  <Box sx={{ p: 0.2 }}>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        fontWeight: 500,
                        fontSize: '0.7rem'
                      }}
                    >
                      ✨ Coming soon!
                    </Typography>
                    <Typography 
                      variant="caption" 
                      sx={{ 
                        display: 'block', 
                        opacity: 0.9,
                        fontSize: '0.65rem',
                        mt: 0.3
                      }}
                    >
                      Get recommendations based on
                      <br />
                      latest research
                    </Typography>
                  </Box>
                }
                placement="top"
                arrow
                enterTouchDelay={0}
                leaveTouchDelay={1500}
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: 'rgba(67, 83, 52, 0.95)',
                      '& .MuiTooltip-arrow': {
                        color: 'rgba(67, 83, 52, 0.95)'
                      },
                      borderRadius: '6px',
                      boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                      maxWidth: '200px'
                    }
                  }
                }}
              >
                <Box 
                  sx={{ 
                    display: 'inline-flex',
                    cursor: 'pointer'
                  }}
                >
                  <Switch
                    disabled
                    size="small"
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        '&.Mui-disabled': {
                          '& + .MuiSwitch-track': {
                            opacity: 1,
                            backgroundColor: '#E2E8F0 !important',
                            border: '1px solid #CBD5E0',
                            backgroundImage: `repeating-linear-gradient(
                              -45deg,
                              #CBD5E0 0px,
                              #CBD5E0 4px,
                              transparent 4px,
                              transparent 8px
                            )`
                          }
                        }
                      },
                      '& .MuiSwitch-thumb': {
                        backgroundColor: '#94A3B8',
                        boxShadow: '0 1px 2px rgba(0,0,0,0.1)'
                      }
                    }}
                  />
                </Box>
              </Tooltip>
            </Box>
          </Box>

          <Box 
            className="scrollable-chips" 
            sx={{ 
              display: 'flex',
              flexWrap: 'wrap',
              gap: 0.5,
              maxHeight: '60px',
              overflowY: 'auto',
              pb: 0.5,
              position: 'relative',
              backgroundColor: '#F7FAFC',
              border: '1px solid #E2E8F0',
              borderRadius: '8px',
              padding: '4px',
              '&::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '12px',
                background: 'linear-gradient(to top, rgba(0,0,0,0.05) 0%, transparent 100%)',
                pointerEvents: 'none',
                borderBottomLeftRadius: '8px',
                borderBottomRightRadius: '8px',
              },
              '&::-webkit-scrollbar': {
                width: '4px',
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'transparent',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(24, 80, 2, 0.2)',
                borderRadius: '2px',
                '&:hover': {
                  backgroundColor: 'rgba(24, 80, 2, 0.3)',
                }
              }
            }}
          >
            {sampleGoals.map((goal, index) => (
              <Chip
                key={index}
                label={goal}
                onClick={() => handleSampleGoalClick(goal)}
                size="small"
                sx={{
                  borderRadius: '6px',
                  height: '24px',
                  fontSize: '0.80rem',
                  backgroundColor: '#fff',
                  border: '1px solid #E2E8F0',
                  '&:hover': {
                    backgroundColor: '#EDF2F7',
                    borderColor: '#CBD5E0',
                  },
                }}
              />
            ))}
          </Box>
        </Box>

        <Collapse in={showError}>
          <Alert 
            severity="info" 
            sx={{ 
              mt: 2,
              borderRadius: '8px',
              '& .MuiAlert-icon': {
                color: '#435334'
              }
            }}
          >
            Please enter your health goals or select from the suggestions above
          </Alert>
        </Collapse>

        <Button
          variant="contained"
          fullWidth={variant === 'landing'}
          onClick={handleButtonClick}
          disabled={loading}
          size="medium"
          sx={{
            mt: 4,
            ...buttonStyles.primary,
            position: 'relative',
            overflow: 'hidden',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
              transform: 'translateX(-100%)',
              transition: 'transform 0.3s ease',
            },
            '&:hover::after': {
              transform: 'translateX(100%)',
            },
            animation: !healthGoals.trim() ? `${shimmer} 2s infinite` : 'none'
          }}
        >
          {loading ? (
            <Box sx={buttonStyles.iconWrapper}>
              <CircularProgress size={16} color="inherit" />
              <span>Analyzing...</span>
            </Box>
          ) : (
            <Box sx={buttonStyles.iconWrapper}>
              <AutoFixHighIcon sx={{ fontSize: '1rem' }} />
              <span>Recommend Stack</span>
            </Box>
          )}
        </Button>

        {variant === 'landing' && (
          <Typography 
            variant="caption" 
            color="text.secondary"
            sx={{ 
              display: 'block', 
              textAlign: 'center',
              mt: 2,
              opacity: 0.8
            }}
          >
            Try it now — Get instant Supplement Suggestions
          </Typography>
        )}
      </Box>
    </Box>
  );
} 