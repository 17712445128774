import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  CircularProgress,
  Paper,
  Pagination,
  IconButton,
} from '@mui/material';
import config from '../config';
import './Research.css';
import { useParams, Link } from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const Research = () => {
  const { supplementKey, conditionKey } = useParams();
  const [researchArticles, setResearchArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeSearch, setActiveSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 0,
    totalItems: 0,
    hasNextPage: false,
    hasPreviousPage: false
  });

  // Fetch articles when search or filters change
  useEffect(() => {
    fetchResearchArticles(1);
  }, [supplementKey, conditionKey, activeSearch]);

  const fetchResearchArticles = async (page = 1) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        mode: 'list',
        page: page.toString(),
        pageSize: pagination.pageSize.toString()
      });

      // Always include supplement/condition key regardless of search
      if (supplementKey) {
        queryParams.append('supplementKey', supplementKey);
      } else if (conditionKey) {
        queryParams.append('conditionKey', conditionKey);
      }

      // Add search parameter if there's an active search
      if (activeSearch) {
        queryParams.append('search', activeSearch);
      }

      const response = await fetch(
        `${config.EDGE_FUNCTION_URL}/getResearchArticles?${queryParams}`,
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        console.error('Server response:', errorData);
        throw new Error(
          errorData?.details || 
          `HTTP error! status: ${response.status}`
        );
      }

      const { data, pagination: paginationData } = await response.json();
      
      if (!data) {
        throw new Error('No data received from server');
      }

      setResearchArticles(data);
      setPagination(paginationData);
    } catch (error) {
      console.error('Error fetching articles:', error);
      setResearchArticles([]);
      setPagination({
        page: 1,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0,
        hasNextPage: false,
        hasPreviousPage: false
      });
    } finally {
      setLoading(false);
      setIsSearching(false);
    }
  };

  // Save scroll position before navigating to article
  const handleArticleClick = () => {
    const currentScrollPosition = window.scrollY;
    sessionStorage.setItem('researchScrollPosition', currentScrollPosition.toString());
  };

  // Restore scroll position when component mounts
  useEffect(() => {
    const savedPosition = sessionStorage.getItem('researchScrollPosition');
    if (savedPosition) {
      setTimeout(() => {
        window.scrollTo({
          top: parseInt(savedPosition, 10),
          behavior: 'instant'
        });
        sessionStorage.removeItem('researchScrollPosition');
      }, 100);
    }
  }, [researchArticles]); // Add researchArticles as dependency to ensure content is loaded

  // Format helpers
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${year}-${month}`;
  };

  const formatAuthorName = (authorName) => {
    if (!authorName || authorName.trim() === '') return '';
    return `${authorName.split(',')[0]} et al.`;
  };

  // Update the format helper to properly capitalize and format the name
  const formatSupplementName = (key) => {
    if (!key) return '';
    return key
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Handle page change
  const handlePageChange = (event, newPage) => {
    fetchResearchArticles(newPage);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const filteredArticles = researchArticles;

  const handleSearch = () => {
    setActiveSearch(searchQuery);
  };

  const handleClear = () => {
    setSearchQuery('');
    setActiveSearch('');
  };

  return (
    <Box sx={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      px: { xs: 2, sm: 3 }, // Responsive padding
    }}>
      <Box sx={{ 
        width: '100%',
        maxWidth: '1200px',
      }}>
        {/* Simple header with search */}
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mb: 2,
          mt: 2,
        }}>
          {/* Search bar and count */}
          <Box sx={{ 
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'stretch', sm: 'center' },
            gap: 2,
            justifyContent: 'space-between'
          }}>
            {/* Search input */}
            <Box sx={{ 
              position: 'relative',
              maxWidth: { xs: '100%', sm: '300px' }
            }}>
              <input
                type="text"
                placeholder="Search articles..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                style={{
                  width: '100%',
                  padding: '8px 36px 8px 12px',
                  fontSize: '14px',
                  borderRadius: '6px',
                  border: '1px solid #ddd',
                  outline: 'none',
                }}
              />
              <Box sx={{
                position: 'absolute',
                right: '8px',
                top: '50%',
                transform: 'translateY(-50%)',
              }}>
                {activeSearch ? (
                  <IconButton
                    size="small"
                    onClick={handleClear}
                  >
                    <ClearIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                ) : (
                  <IconButton
                    size="small"
                    onClick={handleSearch}
                    disabled={!searchQuery}
                  >
                    <SearchIcon sx={{ fontSize: 18 }} />
                  </IconButton>
                )}
              </Box>
            </Box>

            {/* Count and Page Info */}
            {!loading && researchArticles.length > 0 && (
              <Typography variant="body2" color="text.secondary" sx={{ 
                minWidth: 'fit-content',
                alignSelf: { xs: 'flex-start', sm: 'center' }
              }}>
                {activeSearch 
                  ? `Showing ${((pagination.page - 1) * pagination.pageSize) + 1}-${Math.min(pagination.page * pagination.pageSize, pagination.totalItems)} of ${pagination.totalItems} results`
                  : `Showing ${((pagination.page - 1) * pagination.pageSize) + 1}-${Math.min(pagination.page * pagination.pageSize, pagination.totalItems)} of ${pagination.totalItems} articles`}
              </Typography>
            )}
          </Box>
        </Box>

        {/* Articles content */}
        <Box sx={{ 
          width: '100%',
          px: { xs: 0, sm: 0 } // Add padding only on mobile
        }}>
          {/* Loading, Articles, or No Results */}
          {loading ? (
            <Box sx={{ textAlign: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : researchArticles.length > 0 ? (
            <Box sx={{ width: '100%' }}>
              {filteredArticles.map((article) => (
                <Paper
                  key={article.id}
                  sx={{
                    marginBottom: 4,
                    padding: { xs: 2, sm: 3 },
                    transition: 'box-shadow 0.3s',
                    '&:hover': {
                      boxShadow: 6,
                    },
                    width: '100%',
                    mx: { xs: 0, sm: 'auto' },
                  }}
                >
                  {/* Simplified Title */}
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      marginBottom: 1,
                      color: 'text.primary',
                    }}
                  >
                    {article.ai_title_simplified}
                  </Typography>

                  {/* Original Title */}
                  {article.title && (
                    <Typography
                      variant="subtitle2"
                      color="textSecondary"
                      sx={{ fontStyle: 'italic', marginBottom: 1 }}
                    >
                      {article.title}
                    </Typography>
                  )}

                  {/* Publication Details */}
                  <Typography 
                    variant="body2" 
                    color="textSecondary" 
                    sx={{ marginBottom: 2 }}
                  >
                    {formatDate(article.date_published)} • {article.journal_title} •{' '}
                    {formatAuthorName(article.first_author_name)}
                  </Typography>

                  {/* Abstract Summary */}
                  {article.ai_abstract_summary && (
                    <Typography 
                      variant="body2" 
                      color="text.secondary" 
                      sx={{ marginBottom: 2 }}
                    >
                      {article.ai_abstract_summary.length > 300
                        ? article.ai_abstract_summary.substring(0, 300) + '...'
                        : article.ai_abstract_summary}
                    </Typography>
                  )}

                  {/* Read More Link */}
                  <Link
                    to={`/${supplementKey ? 'supplements' : 'conditions'}/${supplementKey || conditionKey}/research/${article.article_ref}`}
                    onClick={handleArticleClick}
                    style={{
                      fontWeight: 'normal',
                      textDecoration: 'underline',
                      color: '#2e7d32',
                      transition: 'color 0.3s',
                    }}
                    onMouseEnter={(e) => e.target.style.color = '#1b5e20'}
                    onMouseLeave={(e) => e.target.style.color = '#2e7d32'}
                  >
                    Read More
                  </Link>
                </Paper>
              ))}
              
              {/* Pagination */}
              {pagination.totalPages > 1 && (
                <Box sx={{ textAlign: 'center', my: 4 }}>
                  <Pagination
                    count={pagination.totalPages}
                    page={pagination.page}
                    onChange={handlePageChange}
                    color="primary"
                    size="large"
                    showFirstButton
                    showLastButton
                  />
                </Box>
              )}
            </Box>
          ) : (
            <Box sx={{ textAlign: 'center', my: 4 }}>
              <Typography>
                {activeSearch 
                  ? 'No articles found matching your search.'
                  : 'No research articles available.'}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Research;
