import { buttonStyles } from './ButtonStyles';

export const authStyles = {
  // Container and Paper styles
  paper: {
    padding: 4,
    marginTop: 8,
    borderRadius: '16px',
    background: '#fff',
    boxShadow: '0 2px 12px rgba(0,0,0,0.08)'
  },
  
  // Typography
  title: {
    color: '#435334',
    fontWeight: 700,
    mb: 3
  },

  // Form Elements
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: '#F7FAFC',
      '&:hover': {
        backgroundColor: '#EDF2F7',
      },
      '&.Mui-focused': {
        backgroundColor: '#F7FAFC',
        boxShadow: '0 0 0 2px rgba(67, 83, 52, 0.2)',
      }
    }
  },
  
  formGrid: {
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
    gap: 2,
    mt: 2
  },

  select: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      backgroundColor: '#F7FAFC',
      '&:hover': {
        backgroundColor: '#EDF2F7',
      },
      '&.Mui-focused': {
        backgroundColor: '#F7FAFC',
        boxShadow: '0 0 0 2px rgba(67, 83, 52, 0.2)',
      }
    }
  },

  // Buttons
  submitButton: {
    ...buttonStyles.primary,
    mt: 3,
    mb: 2
  },

  secondaryButton: {
    ...buttonStyles.secondary,
    mt: 2
  },

  dangerButton: {
    color: 'error.main',
    mt: 2,
    '&:hover': {
      backgroundColor: 'rgba(211, 47, 47, 0.04)',
    }
  },

  // Messages and Alerts
  errorMessage: {
    mt: 2, 
    textAlign: 'center',
    backgroundColor: 'rgba(211, 47, 47, 0.1)',
    padding: 2,
    borderRadius: '8px'
  },

  successMessage: {
    mt: 2,
    textAlign: 'center',
    backgroundColor: 'rgba(67, 83, 52, 0.1)',
    padding: 2,
    borderRadius: '8px'
  },

  // Dialog styles
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: '16px',
      padding: 2
    }
  },

  // Google Sign-in button (if we want to style it consistently)
  googleButton: {
    width: '100%',
    mt: 2,
    borderRadius: '12px',
    border: '1px solid #E2E8F0',
    padding: '10px 16px',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#F7FAFC'
    }
  }
}; 