import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { Container, Button, Typography, Box, Paper, Link, Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { authStyles } from '../common/AuthStyles';
import UserForm from './UserForm';
import dayjs from 'dayjs';

function Register() {
  const [formData, setFormData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    password: '',
    confirmPassword: ''
  });
  const [agreed, setAgreed] = useState(false);
  const [message, setMessage] = useState('');
  const [formValid, setFormValid] = useState(false);

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const { email, firstName, lastName, dateOfBirth, password, confirmPassword } = formData;
    if (email && firstName && lastName && dateOfBirth && 
        password && confirmPassword && agreed && 
        password === confirmPassword) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData, agreed]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const { email, password, firstName, lastName, dateOfBirth, gender } = formData;

    // Age validation - check if birth month is at least 18 years ago
    const birthDate = dayjs(dateOfBirth);
    const eighteenYearsAgo = dayjs().subtract(18, 'year');
    
    if (birthDate.isAfter(eighteenYearsAgo)) {
      setMessage('You must be at least 18 years old to register.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      
      await setDoc(doc(db, 'users', user.uid), {
        email,
        firstName,
        lastName,
        dateOfBirth,
        gender,
        createdAt: new Date().toISOString()
      });

      navigate('/dashboard');
    } catch (error) {
      // Handle specific error cases with user-friendly messages
      let errorMessage = 'Registration failed. Please try again.';
      
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'An account with this email already exists.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Please enter a valid email address.';
          break;
        case 'auth/weak-password':
          errorMessage = 'Password should be at least 6 characters long.';
          break;
        case 'auth/network-request-failed':
          errorMessage = 'Network error. Please check your connection.';
          break;
        // Don't expose other Firebase errors to the user
      }
      
      setMessage(errorMessage);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={0} sx={authStyles.paper}>
        <Typography component="h1" variant="h4" sx={authStyles.title}>
          Create Account
        </Typography>

        <Box component="form" onSubmit={handleRegister} noValidate>
          <UserForm 
            formData={formData}
            handleChange={handleChange}
            showPassword={true}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                color="primary"
              />
            }
            label={
              <Typography variant="body2">
                I agree to the <Link href="/terms-and-conditions" target="_blank">Terms and Conditions</Link> and the <Link href="/privacy-policy" target="_blank">Privacy Policy</Link>.
              </Typography>
            }
            sx={{ mt: 2 }}
          />

          <Button
            type="submit"
            fullWidth
            disabled={!formValid}
            sx={authStyles.submitButton}
          >
            Register
          </Button>

          {message && (
            <Typography color="error" sx={authStyles.errorMessage}>
              {message}
            </Typography>
          )}

          <Button
            fullWidth
            onClick={() => navigate('/login')}
            sx={authStyles.secondaryButton}
          >
            Already have an account? Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default Register; 