import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { collection, query, where, getDocs, doc, deleteDoc, updateDoc, getDoc } from 'firebase/firestore';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  Button,
  Switch,
  TextField,
  Tooltip,
  Paper,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, Share as ShareIcon, Close as CloseIcon, ContentCopy as CopyIcon } from '@mui/icons-material';
import { db } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import PublicIcon from '@mui/icons-material/Public';
import CreateStackButton from './CreateStackButton';

function Dashboard() {
  const [userName, setUserName] = useState('');
  const [stacks, setStacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [supplementMap, setSupplementMap] = useState({});
  const [shareUrls, setShareUrls] = useState({});
  const [showShareField, setShowShareField] = useState({});
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [stackToDelete, setStackToDelete] = useState(null);

  const navigate = useNavigate();
  const theme = useTheme();

  useEffect(() => {
    async function fetchUserData() {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        // Fetch user data
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserName(userData.firstName || user.displayName || user.email);
        } else {
          
        }

        // Fetch user stacks from Firestore
        const q = query(collection(db, 'stacks'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(q);
        const stacksData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setStacks(stacksData);
        setLoading(false);
      }
    }

    fetchUserData();
  }, []);

  const handleEdit = (stackId) => {
    navigate(`/edit-stack/${stackId}`);
  };

  const handleRemove = (stackId) => {
    setStackToDelete(stackId);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      const stackId = stackToDelete;
      // Fetch the stack document to get references
      const stackDoc = await getDoc(doc(db, 'stacks', stackId));
      if (stackDoc.exists()) {
        const stackData = stackDoc.data();
        const healthGoalsRef = stackData.healthGoalsRef;
        const notesRef = stackData.notesRef;

        // Delete the referenced documents
        if (healthGoalsRef) {
          await deleteDoc(doc(db, 'healthGoals', healthGoalsRef));
        }
        if (notesRef) {
          await deleteDoc(doc(db, 'notes', notesRef));
        }

        // Delete the stack document
        await deleteDoc(doc(db, 'stacks', stackId));
        setStacks(stacks.filter(stack => stack.id !== stackId));
      }
    } catch (error) {
      console.error('Error deleting stack:', error);
    } finally {
      setDeleteConfirmOpen(false);
      setStackToDelete(null);
    }
  };

  const handleTogglePublic = async (stackId, currentStatus) => {
    try {
      const stackRef = doc(db, 'stacks', stackId);
      await updateDoc(stackRef, { isPublic: !currentStatus });
      setStacks(stacks.map(stack =>
        stack.id === stackId ? { ...stack, isPublic: !currentStatus } : stack
      ));
    } catch (error) {
      console.error('Error updating stack public status:', error);
    }
  };

  const handleShare = (stackId) => {
    const shareUrl = `${window.location.origin}/view-stack/${stackId}`;
    setShareUrls({
      ...shareUrls,
      [stackId]: shareUrl,
    });
    setShowShareField({
      ...showShareField,
      [stackId]: true,
    });
  };

  const handleShareFieldClose = (stackId) => {
    setShowShareField({
      ...showShareField,
      [stackId]: false,
    });
  };

  const handleCopyToClipboard = (url) => {
    navigator.clipboard.writeText(url);
  };
  const noStacks = stacks.length === 0 && !loading;

  useEffect(() => {
    // Trigger the Google Ads conversion event when the Dashboard is loaded
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16692189167/5TmOCMbY8c8ZEO-vupc-',
      'value': 1.0,
      'currency': 'USD'
    });
  }, []); // Empty dependency array ensures this runs only once on page load


  return (
    <Container component="main" maxWidth="lg" sx={{ padding: '0 16px', marginBottom: '50px'  }}>
      <Box sx={{ marginTop: 1, marginBottom: 8, display: 'flex', flexDirection: 'column', alignItems: noStacks ? 'center' : 'flex-start',
          justifyContent: noStacks ? 'center' : 'flex-start' }}>
        
        {noStacks ? (
          <Card
            sx={{
              padding: { xs: theme.spacing(2), sm: theme.spacing(4) },
              textAlign: 'center',
              maxWidth: 600,
              marginTop: { xs: 3, sm: 5 },
              boxShadow: theme.shadows[3],
              borderRadius: '16px',
            }}
          >
            <CardContent>
              <Typography 
                component="h4" 
                variant="h4" 
                sx={{ 
                  fontSize: { xs: '1.5rem', sm: '2rem' },
                  fontWeight: 500,
                  marginBottom: 2,
                  color: theme.palette.text.primary,
                  letterSpacing: '-0.01em'
                }}
              >
                Hi {userName}!
              </Typography>
              <Typography 
                variant="h5" 
                sx={{ 
                  marginBottom: 1,
                  fontSize: { xs: '1.25rem', sm: '1.6rem' },
                  fontWeight: 400,
                  color: theme.palette.text.primary,
                  letterSpacing: '0.01em',
                  lineHeight: 1.4
                }}
              >
                Boost your potential
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  marginBottom: 4,
                  color: theme.palette.text.secondary,
                  fontSize: { xs: '0.95rem', sm: '1rem' },
                  lineHeight: 1.6,
                  maxWidth: '90%',
                  mx: 'auto'
                }}
              >
                Build a supplement stack with AI or add and analyze your current stacks
              </Typography>
              <CreateStackButton></CreateStackButton>
            </CardContent>
          </Card>
        ) : (
<div>
  <Typography 
    component="h1" 
    variant="h5" 
    sx={{ 
      marginBottom: 4,
      fontWeight: 500,
      letterSpacing: '0.3px'
    }}
  >
    Hi {userName}!
  </Typography>
  <CreateStackButton></CreateStackButton>
</div>
        )
       
      }
      </Box>
      <Box>
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {stacks.map((stack) => (
            <Grid item xs={12} sm={6} md={4} key={stack.id}>
              <Card sx={{ 
                boxShadow: '0 2px 8px rgba(0,0,0,0.12)', 
                borderRadius: '12px',
                border: '1px solid #e0e0e0',
                '&:hover': { 
                  boxShadow: '0 4px 12px rgba(0,0,0,0.16)',
                  borderColor: '#d0d0d0'
                }, 
                transition: 'all 0.2s ease',
                position: 'relative',
                backgroundColor: '#ffffff'
              }}>
                {stack.origin === 'AI' && (
                  <Chip
                    label="AI"
                    color="secondary"
                    size="small"
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                  />
                )}
                <CardContent>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography 
                      component="h5" 
                      variant="h6" 
                      sx={{ 
                        marginLeft: 1,
                        fontWeight: 500,
                        fontSize: { xs: '1.1rem', sm: '1.25rem' },
                        letterSpacing: '0.01em',
                        color: theme.palette.text.primary,
                        lineHeight: 1.3
                      }}
                    >
                      {stack.name}
                    </Typography>
                  </Box>
                  <Box sx={{ marginTop: 2 }}> 
                    <TableContainer 
                      component={Paper} 
                      sx={{ 
                        boxShadow: 'none',
                        border: '1px solid #f0f0f0',
                        borderRadius: '8px',
                        width: '95%',
                        margin: '0 auto',
                        '& .MuiTableCell-root': {
                          padding: { xs: '6px 12px', sm: '8px 16px' },
                          fontSize: { xs: '0.813rem', sm: '0.875rem' },
                          lineHeight: 1.4,
                          '&:last-child': {
                            textAlign: 'right',
                            paddingRight: { xs: '16px', sm: '20px' }
                          }
                        },
                        '& .MuiTableHead-root .MuiTableCell-root': {
                          fontWeight: 500,
                          backgroundColor: theme.palette.primary.main,
                          color: theme.palette.common.white,
                          padding: { xs: '6px 12px', sm: '6px 16px' },
                          fontSize: { xs: '0.75rem', sm: '0.8125rem' },
                          letterSpacing: '0.02em',
                          '&:last-child': {
                            textAlign: 'right',
                            paddingRight: { xs: '16px', sm: '20px' }
                          }
                        }
                      }}
                    >
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>Supplement</TableCell>
                            <TableCell>Dosage</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {stack.supplements.map((supplement, index) => (
                            <TableRow key={index}>
                              <TableCell>
                                {supplementMap[supplement.supplementId] || supplement.name}
                              </TableCell>
                              <TableCell>
                                {`${supplement.dosage} ${supplement.unit}`}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: 1 }}>
                    <Switch
                          checked={stack.isPublic}
                          onChange={() => handleTogglePublic(stack.id, stack.isPublic)}
                          color="primary"
                          icon={<PublicIcon />}
                          fontSize="small"
                          checkedIcon={<PublicIcon fontSize="small" style={{ color: 'green' }} />}
                          marginbottom="0"
                        />
                      <Box display="flex" flexDirection="row" alignItems="center" marginbottom="0">
                        {stack.isPublic && (
                          <IconButton onClick={() => handleShare(stack.id)} aria-label="share" size='small'>
                            <ShareIcon fontSize="small" />
                          </IconButton>
                        )}
                        <IconButton aria-label="edit" onClick={() => handleEdit(stack.id)} size="small">
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton aria-label="delete" onClick={() => handleRemove(stack.id)} size="small">
                          <DeleteIcon fontSize="small" sx={{ color: theme.palette.grey[600] }} />
                        </IconButton>
                      </Box>
                    </Box>
                    {stack.isPublic && showShareField[stack.id] && (
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                        <TextField
                          value={shareUrls[stack.id]}
                          size="small"
                          fontSize="small"
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            readOnly: true,
                            autoFocus: true,
                          }}
                          onFocus={(e) => e.target.select()}
                        />
                        <Tooltip title="Copy to clipboard">
                          <IconButton onClick={() => handleCopyToClipboard(shareUrls[stack.id])} sx={{ ml: 1 }}>
                            <CopyIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => handleShareFieldClose(stack.id)} sx={{ ml: 1 }}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmOpen}
        onClose={() => setDeleteConfirmOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '8px'
          }
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          Delete Stack
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this stack? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button
            onClick={() => setDeleteConfirmOpen(false)}
            variant="outlined"
            sx={{
              borderRadius: '8px',
              textTransform: 'none'
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
            sx={{
              borderRadius: '8px',
              textTransform: 'none'
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Dashboard;