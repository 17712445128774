import React from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { authStyles } from '../common/AuthStyles';

const UserForm = ({ 
  formData,
  handleChange,
  isDisabled = false,
  showPassword = false,
}) => {
  const { 
    email,
    firstName, 
    lastName, 
    dateOfBirth, 
    gender,
    password,
    confirmPassword 
  } = formData;

  // Helper function to check if a date is valid (18 years or older)
  const isDateValid = (date) => {
    if (!date) return false;
    const eighteenYearsAgo = dayjs().subtract(18, 'year');
    return date.isBefore(eighteenYearsAgo, 'month');
  };

  return (
    <Grid container spacing={2}>
      {/* Email - Full width */}
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          required
          fullWidth
          id="email"
          label="Email"
          name="email"
          value={email}
          onChange={handleChange}
          disabled={isDisabled}
          sx={authStyles.textField}
        />
      </Grid>

      {/* Name fields - Side by side */}
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          value={firstName}
          onChange={handleChange}
          sx={authStyles.textField}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          value={lastName}
          onChange={handleChange}
          sx={authStyles.textField}
        />
      </Grid>

      {/* Date of Birth and Gender section */}
      <Grid item xs={12}>
        <Typography variant="caption" color="text.secondary" sx={{ mb: 1, display: 'block' }}>
          Age and gender help us provide personalized supplement recommendations
        </Typography>
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Birth Month"
            views={['year', 'month']}
            value={dateOfBirth ? dayjs(dateOfBirth) : null}
            onChange={(newValue) => {
              handleChange({
                target: {
                  name: 'dateOfBirth',
                  value: newValue ? newValue.format('YYYY-MM') : ''
                }
              });
            }}
            maxDate={dayjs().subtract(18, 'year').subtract(1, 'month').endOf('month')}
            minDate={dayjs('1900-01')}
            defaultView="year"
            sx={{ ...authStyles.textField, width: '100%' }}
            slotProps={{
              textField: {
                helperText: 'Must be 18 or older',
                InputProps: {
                  readOnly: true,
                }
              },
              actionBar: {
                actions: ['accept']
              }
            }}
          />
        </LocalizationProvider>
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth>
          <InputLabel id="gender-label">Gender</InputLabel>
          <Select
            labelId="gender-label"
            id="gender"
            name="gender"
            value={gender}
            onChange={handleChange}
            label="Gender"
          >
            <MenuItem value="">
              <em>Prefer not to say</em>
            </MenuItem>
            <MenuItem value="male">Male</MenuItem>
            <MenuItem value="female">Female</MenuItem>
            <MenuItem value="other">Other</MenuItem>
          </Select>
        </FormControl>
      </Grid>

      {/* Password fields - Full width */}
      {showPassword && (
        <>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={handleChange}
              sx={authStyles.textField}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={handleChange}
              sx={authStyles.textField}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default UserForm; 