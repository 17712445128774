import React, { useState, useEffect } from 'react';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider, deleteUser } from 'firebase/auth';
import { doc, getDoc, updateDoc, deleteDoc, collection, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { Container, Button, Typography, Box, Paper, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import { authStyles } from '../common/AuthStyles';
import UserForm from './UserForm';
import dayjs from 'dayjs';
import { buttonStyles } from '../common/ButtonStyles';

function Profile() {
  // Consolidate initial states
  const initialFormData = {
    email: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: ''
  };

  const initialPasswordState = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  };

  // States
  const [formData, setFormData] = useState(initialFormData);
  const [formValid, setFormValid] = useState(false);
  
  // Message states
  const [messages, setMessages] = useState({
    profile: '',
    password: '',
    delete: ''
  });
  
  // Modal states
  const [modalStates, setModalStates] = useState({
    password: false,
    delete: false
  });
  
  // Other states
  const [passwordChangeState, setPasswordChangeState] = useState(initialPasswordState);
  const [deleteAccountPassword, setDeleteAccountPassword] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  // Add a new state for welcome message
  const [welcomeMessage, setWelcomeMessage] = useState('');

  // Shared styles for modals
  const modalStyles = {
    paper: {
      m: { xs: 2, sm: 3 },
      width: { xs: 'calc(100% - 32px)', sm: 'auto' },
      borderRadius: 2,
      p: { xs: 2, sm: 3 }
    },
    title: {
      fontSize: { xs: '1.25rem', sm: '1.5rem' },
      mb: 2
    },
    content: {
      p: 0,
      mt: 2
    },
    actions: {
      px: 0,
      pb: 0,
      mt: 3
    },
    button: {
      borderRadius: 2,
      textTransform: 'none',
      px: 3
    }
  };

  const navigate = useNavigate();
  const auth = getAuth();

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setFormData(userDoc.data());
        }
      }
    };
    fetchUserData();
  }, [auth]);

  // Updated form validation
  useEffect(() => {
    const { firstName, lastName, dateOfBirth } = formData;
    // Remove gender from required fields
    setFormValid(
      !!firstName?.trim() && 
      !!lastName?.trim() && 
      !!dateOfBirth
    );
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const { firstName, lastName, dateOfBirth } = formData;

    // Remove gender from validation check
    if (!firstName?.trim() || !lastName?.trim() || !dateOfBirth) {
      setMessages(prev => ({ ...prev, profile: 'Please fill out all required fields' }));
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        setMessages(prev => ({ ...prev, profile: 'No user found' }));
        return;
      }

      await updateDoc(doc(db, 'users', user.uid), {
        ...formData,
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        updatedAt: new Date().toISOString()
      });
      
      navigate('/dashboard');
    } catch (error) {
      setMessages(prev => ({ ...prev, profile: 'Failed to update profile. Please try again.' }));
      console.error(error);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (passwordChangeState.newPassword.trim() !== passwordChangeState.confirmNewPassword.trim()) {
      setMessages(prev => ({ ...prev, password: 'New passwords do not match' }));
      return;
    }
    try {
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(
        user.email, 
        passwordChangeState.currentPassword
      );
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, passwordChangeState.newPassword);
      setMessages(prev => ({ ...prev, password: 'Password updated successfully' }));
      setModalStates(prev => ({ ...prev, password: false }));
    } catch (error) {
      setMessages(prev => ({ ...prev, password: 'Failed to update password. Please check your current password.' }));
      console.error(error);
    }
  };

  const handleOpenDeleteDialog = () => {
    
    setModalStates(prev => ({ ...prev, delete: true }));
    setMessages(prev => ({ ...prev, delete: '' }));
    setDeleteAccountPassword('');
  };

  const handleCloseDeleteDialog = () => {
    
    setModalStates(prev => ({ ...prev, delete: false }));
    setDeleteAccountPassword('');
    setMessages(prev => ({ ...prev, delete: '' }));
  };

  const handleDeleteAccount = async () => {
    setIsDeleting(true);
    
    try {
      const user = auth.currentUser;
      if (!user) {
        setMessages(prev => ({ ...prev, delete: 'No user found' }));
        setIsDeleting(false);
        return;
      }

      // Check if user is Google-authenticated
      const isGoogleUser = user.providerData.some(
        provider => provider.providerId === 'google.com'
      );

      // Only require password for email/password users
      if (!isGoogleUser && !deleteAccountPassword.trim()) {
        setMessages(prev => ({ ...prev, delete: 'Password is required to delete the account.' }));
        setIsDeleting(false);
        return;
      }

      // Reauthenticate only for email/password users
      if (!isGoogleUser) {
        const credential = EmailAuthProvider.credential(
          user.email, 
          deleteAccountPassword
        );
        await reauthenticateWithCredential(user, credential);
      }

      // Proceed with deletion
      const batch = writeBatch(db);
      batch.delete(doc(db, 'users', user.uid));
      
      // Delete related collections
      const collections = ['stacks', 'healthGoals', 'notes'];
      for (const collectionName of collections) {
        const q = query(collection(db, collectionName), where('userId', '==', user.uid));
        const snapshot = await getDocs(q);
        snapshot.docs.forEach((doc) => batch.delete(doc.ref));
      }

      await batch.commit();
      await deleteUser(user);
      
      setIsDeleting(false);
      setModalStates(prev => ({ ...prev, delete: false }));
      navigate('/');
    } catch (error) {
      console.error('Delete account error:', error);
      setMessages(prev => ({ 
        ...prev, 
        delete: error.code === 'auth/wrong-password' 
          ? 'Incorrect password. Please try again.'
          : 'Failed to delete account. Please try again.'
      }));
      setIsDeleting(false);
    }
  };

  const handleClosePasswordModal = () => {
    setModalStates(prev => ({ ...prev, password: false }));
    setPasswordChangeState(initialPasswordState);
    setMessages(prev => ({ ...prev, password: '' }));
  };

  // Update the useEffect to use the new state instead of messages
  useEffect(() => {
    const profileMessage = sessionStorage.getItem('profileMessage');
    if (profileMessage) {
      setWelcomeMessage(profileMessage);
      sessionStorage.removeItem('profileMessage'); // Clear the message
    }
  }, []);

  // Updated UI components
  return (
    <Container component="main" maxWidth="sm">
      <Paper 
        elevation={0} 
        sx={{
          ...authStyles.paper,
          mt: { xs: 2, sm: 4 },
          p: { xs: 2, sm: 3 },
          borderRadius: 2
        }}
      >
        <Typography 
          component="h1" 
          variant="h4" 
          sx={{
            ...authStyles.title,
            fontSize: { xs: '1.5rem', sm: '2rem' },
            mb: 1
          }}
        >
          Profile Settings
        </Typography>

        <Typography 
          variant="body2" 
          color="text.secondary" 
          sx={{ mb: 3 }}
        >
          Manage your account information and preferences
        </Typography>

        {/* Show welcome message */}
        {welcomeMessage && (
          <Typography 
            sx={{ 
              mb: 3,
              p: 2,
              bgcolor: 'primary.light',
              color: 'primary.contrastText',
              borderRadius: 1,
              textAlign: 'center'
            }}
          >
            {welcomeMessage}
          </Typography>
        )}

        <Box component="form" onSubmit={handleSubmit} noValidate>
          <UserForm 
            formData={formData}
            handleChange={handleChange}
            isDisabled={true}
          />

          {/* Show error messages */}
          {messages.profile && (
            <Typography 
              color="error" 
              sx={{
                ...authStyles.errorMessage,
                mt: 2
              }}
              align="center"
            >
              {messages.profile}
            </Typography>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!formValid}
            sx={buttonStyles.auth}
          >
            Save Changes
          </Button>

          <Button
            onClick={() => setModalStates(prev => ({ ...prev, password: true }))}
            fullWidth
            sx={{
              ...buttonStyles.auth,
              backgroundColor: 'transparent',
              border: '1px solid #435334',
              color: '#435334',
              '&:hover': {
                backgroundColor: 'rgba(67, 83, 52, 0.04)',
                border: '1px solid #435334',
              }
            }}
          >
            Change Password
          </Button>

          <Button
            onClick={() => setModalStates(prev => ({ ...prev, delete: true }))}
            fullWidth
            sx={{
              ...buttonStyles.auth,
              backgroundColor: 'transparent',
              color: '#d32f2f',
              '&:hover': {
                backgroundColor: 'rgba(211, 47, 47, 0.04)',
              }
            }}
          >
            Delete Account
          </Button>
        </Box>
      </Paper>

      {/* Password Change Dialog */}
      <Dialog 
        open={modalStates.password}
        onClose={handleClosePasswordModal}
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: modalStyles.paper }}
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please enter your current password and the new password you would like to set.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="currentPassword"
            label="Current Password"
            type="password"
            fullWidth
            value={passwordChangeState.currentPassword}
            onChange={(e) => setPasswordChangeState(prev => ({
              ...prev,
              currentPassword: e.target.value
            }))}
            error={!!messages.password}
          />
          <TextField
            margin="dense"
            name="newPassword"
            label="New Password"
            type="password"
            fullWidth
            value={passwordChangeState.newPassword}
            onChange={(e) => setPasswordChangeState(prev => ({
              ...prev,
              newPassword: e.target.value
            }))}
            error={!!messages.password}
          />
          <TextField
            margin="dense"
            name="confirmNewPassword"
            label="Confirm New Password"
            type="password"
            fullWidth
            value={passwordChangeState.confirmNewPassword}
            onChange={(e) => setPasswordChangeState(prev => ({
              ...prev,
              confirmNewPassword: e.target.value
            }))}
            error={!!messages.password}
            helperText={messages.password}
          />
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClosePasswordModal} 
            sx={{
              ...buttonStyles.auth,
              backgroundColor: 'transparent',
              color: '#435334',
              '&:hover': {
                backgroundColor: 'rgba(67, 83, 52, 0.04)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleChangePassword} 
            sx={buttonStyles.auth}
          >
            Update Password
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Account Dialog */}
      <Dialog 
        open={modalStates.delete}
        onClose={(_, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') return;
          setModalStates(prev => ({ ...prev, delete: false }));
        }}
        fullWidth
        maxWidth="sm"
        PaperProps={{ sx: modalStyles.paper }}
      >
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Deleting your account is permanent and will remove all your data. 
            This action cannot be undone.
            {!auth.currentUser?.providerData.some(provider => provider.providerId === 'google.com') && 
              ' Please enter your password to confirm.'}
          </DialogContentText>
          
          {/* Only show password field for email/password users */}
          {!auth.currentUser?.providerData.some(provider => provider.providerId === 'google.com') && (
            <TextField
              autoFocus
              margin="dense"
              name="deletePassword"
              label="Current Password"
              type="password"
              fullWidth
              value={deleteAccountPassword}
              onChange={(e) => setDeleteAccountPassword(e.target.value)}
              error={!!messages.delete}
              helperText={messages.delete}
              disabled={isDeleting}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => {
              setModalStates(prev => ({ ...prev, delete: false }));
              setDeleteAccountPassword('');
              setMessages(prev => ({ ...prev, delete: '' }));
            }}
            disabled={isDeleting}
            sx={{
              ...buttonStyles.auth,
              backgroundColor: 'transparent',
              color: '#435334',
              '&:hover': {
                backgroundColor: 'rgba(67, 83, 52, 0.04)',
              }
            }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteAccount}
            disabled={(!auth.currentUser?.providerData.some(provider => 
              provider.providerId === 'google.com') && 
              !deleteAccountPassword.trim()) || isDeleting}
            sx={{
              ...buttonStyles.auth,
              backgroundColor: '#d32f2f',
              '&:hover': {
                backgroundColor: '#b71c1c',
              }
            }}
          >
            {isDeleting ? 'Deleting...' : 'Delete Account'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Profile; 