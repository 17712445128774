import React, { useState } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Container, 
  TextField,
  Paper,
  Grid,
  IconButton,
  InputAdornment,
  Chip,
  useMediaQuery 
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ClearIcon from '@mui/icons-material/Clear';
import ArticleIcon from '@mui/icons-material/Article';
import ScienceIcon from '@mui/icons-material/Science';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import TimelineIcon from '@mui/icons-material/Timeline';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import AndroidIcon from '@mui/icons-material/Android';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { buttonStyles } from './common/ButtonStyles';
import StackCreationForm from './common/StackCreationForm';
import { keyframes } from '@mui/material/styles';
import googlePlayBadge from '../assets/images/google-play-badge.png';  // Adjust the path based on your file structure

const shimmer = keyframes`
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
`;

const float = keyframes`
  0%, 100% { transform: translateY(0) rotate(0); }
  50% { transform: translateY(-20px) rotate(5deg); }
`;

export default function LandingPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [healthGoals, setHealthGoals] = useState('');

  const handleGetStarted = () => {
    if (healthGoals.trim()) {
      const goals = healthGoals.trim();
      
      
      // Store goals and flag in sessionStorage
      sessionStorage.setItem('pendingHealthGoals', goals);
      sessionStorage.setItem('shouldGenerateStack', 'true');
       // Navigate with source parameter
      const url = '/create-stack?source=landing';
  
      navigate(url);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* Hero Section with Stack Creation UI */}
      <Box sx={{
        background: 'linear-gradient(135deg, #e8f1e8 0%, #174f04 100%)',
        minHeight: '100vh',
        display: 'flex',
        alignItems: { xs: 'flex-start', md: 'center' },
        position: 'relative',
        overflow: 'hidden',
        pt: { xs: '1.5rem', sm: '2rem', md: 0 },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `
            linear-gradient(90deg, rgba(255,255,255,0.03) 1px, transparent 1px),
            linear-gradient(0deg, rgba(255,255,255,0.03) 1px, transparent 1px)
          `,
          backgroundSize: '50px 50px',
          pointerEvents: 'none'
        }
      }}>
        <Container maxWidth="lg">
          <Grid 
            container 
            spacing={{ xs: 1, md: 6 }} 
            alignItems="center"
            justifyContent="center"
            sx={{ 
              position: 'relative', 
              zIndex: 1,
              minHeight: { md: '100%' }
            }}
          >
            <Grid 
              item 
              xs={12} 
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Box sx={{ 
                width: '100%',
                maxWidth: '600px',
                textAlign: 'center',
                mb: { xs: 1, md: 0 },
                mt: { xs: -2, md: 0 }
              }}>
                <Typography 
                  component="h1"
                  variant="h2" 
                  sx={{ 
                    color: '#000',
                    fontWeight: 800,
                    mb: { xs: 2, md: 3 },
                    fontSize: { 
                      xs: '2.0rem',
                      sm: '3rem',
                      md: '3.5rem' 
                    },
                    lineHeight: { xs: 1.2, md: 1.1 },
                    letterSpacing: '-0.02em',
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: '-12px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '80px',
                      height: '4px',
                      background: 'linear-gradient(90deg, #9EB384 0%, #435334 100%)',
                      borderRadius: '2px'
                    }
                  }}
                >
                  Build Your Optimized 
                  <Box 
                    component="span" 
                    sx={{ 
                      color: '#435334',
                      display: 'block',
                      mt: 0.5
                    }}
                  >
                    Supplement Stack
                  </Box>
                </Typography>
              </Box>
            </Grid>
            
            <Grid 
              item 
              xs={12} 
              md={6}
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Paper 
                elevation={0} 
                sx={{ 
                  width: '100%',
                  maxWidth: { xs: '100%', sm: '500px' },
                  mt: 1,
                  p: { xs: 2.5, sm: 3 },
                  borderRadius: '20px',
                  backdropFilter: 'blur(10px)',
                  backgroundColor: 'rgba(255,255,255,0.95)',
                  mx: 'auto',
                  boxShadow: '0 8px 32px rgba(0,0,0,0.08)',
                  transform: { md: 'translateY(-20px)' },
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: { md: 'translateY(-25px)' },
                  }
                }}
              >
                <StackCreationForm
                  healthGoals={healthGoals}
                  setHealthGoals={setHealthGoals}
                  onSubmit={handleGetStarted}
                  variant="landing"
                />
                
                <Box 
                  sx={{ 
                    mt: 2,
                    pt: 3,
                    borderTop: '1px solid rgba(0,0,0,0.1)',
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center'
                  }}
                >
                  <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={() => navigate('/login')}
                    sx={{
                      borderRadius: '8px',
                      px: 3
                    }}
                  >
                    Login
                  </Button>
                  <Button 
                    variant="outlined" 
                    color="primary" 
                    onClick={() => navigate('/register')}
                    sx={{
                      borderRadius: '8px',
                      px: 3
                    }}
                  >
                    Sign up
                  </Button>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Features Section */}
      <Box sx={{ 
        py: { xs: 6, md: 8 },
        background: 'linear-gradient(180deg, #fff 0%, rgba(158, 179, 132, 0.1) 100%)',
        position: 'relative',
        overflow: 'hidden'
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            {[
              {
                icon: <AutoFixHighIcon sx={{ fontSize: 40 }} />,
                title: 'AI-Powered Analysis',
                description: 'Get personalized supplement recommendations based on your unique health goals'
              },
              {
                icon: <ScienceIcon sx={{ fontSize: 40 }} />,
                title: 'Evidence-Based',
                description: 'Recommendations backed by peer-reviewed scientific research'
              },
              {
                icon: <DashboardIcon sx={{ fontSize: 40 }} />,
                title: 'Smart Dashboard',
                description: 'Track progress and manage your supplement routine effortlessly'
              }
            ].map((feature, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Paper
                  elevation={0}
                  sx={{
                    p: 4,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    background: 'rgba(255, 255, 255, 0.8)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '24px',
                    transition: 'all 0.3s ease',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                      transform: 'translateY(-8px)',
                      boxShadow: '0 12px 24px rgba(0,0,0,0.1)',
                      '& .icon-wrapper': {
                        transform: 'scale(1.1)',
                        background: 'linear-gradient(135deg, #9EB384 0%, #435334 100%)',
                        '& svg': {
                          color: '#fff'
                        }
                      }
                    }
                  }}
                >
                  <Box
                    className="icon-wrapper"
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      background: 'rgba(158, 179, 132, 0.1)',
                      mb: 3,
                      transition: 'all 0.3s ease',
                      '& svg': {
                        color: '#435334',
                        transition: 'all 0.3s ease'
                      }
                    }}
                  >
                    {feature.icon}
                  </Box>
                  <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {feature.description}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Research Section - Enhanced */}
      <Box sx={{ 
        py: { xs: 6, md: 10 }, 
        backgroundColor: '#fff',
        position: 'relative',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '1px',
          background: 'linear-gradient(90deg, transparent 0%, rgba(0,0,0,0.1) 50%, transparent 100%)',
 
        }
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                position: 'relative',
                p: { xs: 2, md: 4 },
                borderRadius: '24px',
                background: 'linear-gradient(135deg, rgba(158, 179, 132, 0.05) 0%, rgba(67, 83, 52, 0.05) 100%)',
              }}>
                <Box sx={{ position: 'relative', zIndex: 1 }}>
                  <Typography 
                    variant="h3" 
                    gutterBottom
                    sx={{
                      fontSize: { xs: '2rem', md: '2.5rem' },
                      fontWeight: 700,
                      background: 'linear-gradient(135deg, #435334 0%, #9EB384 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      mb: 3
                    }}
                  >
                    Research-Backed Recommendations
                  </Typography>
                  <Typography 
                    variant="body1" 
                    paragraph
                    sx={{
                      fontSize: '1.1rem',
                      lineHeight: 1.7,
                      color: 'text.secondary',
                      mb: 4
                    }}
                  >
                    Access a comprehensive database of scientific research on supplements and their effects.
                    Our AI analyzes thousands of peer-reviewed studies to provide you with evidence-based recommendations that are easy to understand.
                  </Typography>
                  {/* Temporarily hidden until research feature is ready
                  <Button 
                    variant="outlined" 
                    sx={{
                      ...buttonStyles.secondary,
                      borderRadius: '12px',
                      py: 1.5,
                      px: 4
                    }}
                    onClick={() => navigate('/research')}
                  >
                    <Box sx={buttonStyles.iconWrapper}>
                      <ScienceIcon />
                      <span>Explore Research</span>
                    </Box>
                  </Button>
                  */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 2,
                p: 2,
                mt: -7
              }}>
                {[
                  { number: '10,00+', label: 'Research Papers' },
                  { number: '200+', label: 'Supplements Analyzed' },
                  { number: '50+', label: 'Health Categories' },
                  { number: <span style={{ fontSize: '1.4em' }}>∞</span>, label: 'Infinite Stack Combinations' },
                ].map((stat, index) => (
                  <Paper
                    key={index}
                    elevation={0}
                    sx={{
                      p: 3,
                      textAlign: 'center',
                      borderRadius: '16px',
                      background: 'rgba(255, 255, 255, 0.8)',
                      backdropFilter: 'blur(10px)',
                      border: '1px solid rgba(158, 179, 132, 0.1)',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 8px 24px rgba(0,0,0,0.05)'
                      }
                    }}
                  >
                    <Typography 
                      variant="h4" 
                      sx={{ 
                        fontWeight: 700,
                        color: '#435334',
                        mb: 1
                      }}
                    >
                      {stat.number}
                    </Typography>
                    <Typography 
                      variant="body2"
                      sx={{ 
                        color: 'text.secondary',
                        fontWeight: 500
                      }}
                    >
                      {stat.label}
                    </Typography>
                  </Paper>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Dashboard Features Section */}
      <Box sx={{ 
        py: { xs: 8, md: 12 }, 
        backgroundColor: '#f8faf8',
        position: 'relative',
      }}>
        <Container maxWidth="lg">
          <Box sx={{ textAlign: 'center', mb: { xs: 6, md: 8 } }}>
            <Typography
              variant="h2"
              sx={{
                fontSize: { xs: '2rem', md: '2.75rem' },
                fontWeight: 700,
                color: '#435334',
                mb: 4,
                position: 'relative',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  bottom: '-12px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  width: '80px',
                  height: '4px',
                  background: 'linear-gradient(90deg, #9EB384 0%, #435334 100%)',
                  borderRadius: '2px'
                }
              }}
            >
              Mobile App
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: 'text.secondary',
                fontWeight: 400,
                maxWidth: '600px',
                mx: 'auto',
                fontSize: { xs: '1rem', md: '1.1rem' }
              }}
            >
              Continue your journey on the myStack mobile app
            </Typography>
          </Box>

          <Grid container spacing={6} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box sx={{ 
                p: 3,
                borderRadius: '24px',
                border: '1px solid rgba(158, 179, 132, 0.2)',
                background: '#fff',
                boxShadow: '0 20px 40px rgba(0,0,0,0.05)',
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',  // Center children horizontally
                width: '100%',
                maxWidth: '500px'  // Limit width on desktop
              }}>
                {/* App Store Buttons */}
                <Box sx={{ 
                  display: 'flex', 
                  gap: 2,
                  flexDirection: { xs: 'column', sm: 'row' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%'
                }}>
                  {/* Android Button */}
                  <Button
                    component="a"
                    href="https://play.google.com/store/apps/details?id=com.balam.mystack"
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      p: 0,
                      minWidth: 'auto',
                      backgroundColor: 'transparent',
                      border: 'none',
                      boxShadow: 'none',
                      '&:hover': {
                        backgroundColor: 'transparent',
                        opacity: 0.8
                      }
                    }}
                  >
                    <Box
                      component="img"
                      src={googlePlayBadge}
                      alt="Get it on Google Play"
                      sx={{
                        width: '240px',
                        height: 'auto',
                        display: 'block'
                      }}
                    />
                  </Button>
                  
                  {/* iOS Button */}
                  <Button
                    disabled
                    variant="outlined"
                    sx={{
                      width: { xs: '240px', sm: '200px' },  // Narrower on desktop to match visual width
                      height: '71px',
                      py: 0,
                      px: 3,
                      borderRadius: '12px',
                      borderColor: 'rgba(67, 83, 52, 0.3)',
                      color: '#435334',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                      mx: 'auto'  // Center the button
                    }}
                  >
                    <PhoneIphoneIcon sx={{ fontSize: 24 }} />
                    <Box sx={{ textAlign: 'left' }}>
                      <Typography 
                        variant="caption" 
                        display="block" 
                        sx={{ 
                          opacity: 0.8,
                          fontSize: '0.7rem',
                          mb: 0.2
                        }}
                      >
                        Coming Soon to
                      </Typography>
                      <Typography 
                        variant="subtitle2" 
                        sx={{ 
                          fontWeight: 600,
                          fontSize: '0.9rem'
                        }}
                      >
                        App Store
                      </Typography>
                    </Box>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}