import React, { useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import HealingIcon from '@mui/icons-material/Healing';
import { useTheme } from '@mui/material/styles';

function BottomNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const theme = useTheme();

  useEffect(() => {
    if (location.pathname === '/dashboard') {
      setValue(0);
    } else if (location.pathname.startsWith('/conditions')) {
      setValue(1);
    } else if (location.pathname.startsWith('/supplements')) {
      setValue(2);
    } else {
      setValue(-1);
    }
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/conditions');
        break;
      case 2:
        navigate('/supplements');
        break;
      default:
        break;
    }
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 9999 }} elevation={3}>
      <BottomNavigation
        value={value}
        onChange={handleChange}
        showLabels
        sx={{
          backgroundColor: theme.palette.background.paper,
          '& .Mui-selected': {
            color: theme.palette.primary.main,
            transition: 'color 0.3s ease',
            '& .MuiBottomNavigationAction-label': {
              fontWeight: 'bold',
            },
            '& .MuiSvgIcon-root': {
              fontSize: '1.4rem',
            },
            '&::after': {
              content: '""',
              display: 'block',
              width: '100%',
              height: '2px',
              backgroundColor: theme.palette.primary.main,
              position: 'absolute',
              bottom: 0,
              left: 0,
            },
          },
          '& .MuiBottomNavigationAction-root': {
            minWidth: 60,
            padding: '4px 8px',
            position: 'relative',
          },
          '& .MuiBottomNavigationAction-label': {
            fontSize: '0.8rem',
            fontWeight: 500,
          },
        }}
      >
        <BottomNavigationAction label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction label="Conditions" icon={<LocalHospitalIcon />} />
        <BottomNavigationAction label="Supplements" icon={<HealingIcon />} />
      </BottomNavigation>
    </Paper>
  );
}

export default BottomNav;