import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Autocomplete, Box, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import config from '../config';
import useMediaQuery from '@mui/material/useMediaQuery';

function Search({ itemKey, dataKey, detailKey, routeBase, label, onSearchFocus }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const searchInputRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2F${dataKey}.json?alt=media`);
      const data = response.data;
      
      // Ensure data is an array before sorting
      const sortedItems = Array.isArray(data) ? data.sort((a, b) => a.name.localeCompare(b.name)) : [];
      setItems(sortedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleItemChange = (event, value) => {
    if (onSearchFocus) {
      onSearchFocus(false);
    }
    if (value) {
      const isResearchTab = window.location.pathname.includes('/research');
      const newPath = isResearchTab 
        ? `/${routeBase}/${value.key}/research`
        : `/${routeBase}/${value.key}`;
      
      navigate(newPath);
      
      // Reset both the input value and search query
      if (searchInputRef.current) {
        searchInputRef.current.value = '';
        searchInputRef.current.blur();
      }
      setSearchQuery('');
    }
  };

  const handleFocus = () => {
    setIsFocused(false);
    if (onSearchFocus) {
      onSearchFocus(false); // Center the search bar
    }
  };

  const handleBlur = () => {
    if (!searchQuery) {
      setIsFocused(false);
      if (onSearchFocus) {
        onSearchFocus(false); // Reset search bar position if no query
      }
    }
  };

  return (
    <Box
      sx={{
        width: { xs: '92%', sm: '75%', md: '65%' },
        maxWidth: '600px',
        margin: '0 auto',
        position: 'relative',
        transition: 'all 0.3s ease',
        '& .MuiOutlinedInput-root': {
          borderRadius: '12px',
          backgroundColor: 'background.paper',
          boxShadow: '0 4px 20px rgba(0,0,0,0.08)',
          border: '1px solid rgba(158, 179, 132, 0.2)',
          minHeight: isMobile ? '56px' : '48px',
          '&:hover': {
            boxShadow: '0 4px 20px rgba(0,0,0,0.12)',
            border: '1px solid rgba(158, 179, 132, 0.3)',
          },
          '&.Mui-focused': {
            boxShadow: '0 4px 20px rgba(0,0,0,0.12)',
            border: '1px solid #435334',
          }
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none'
        },
        '& .MuiAutocomplete-input': {
          padding: '12px 14px !important',
        },
        '& .MuiInputLabel-root': {
          color: '#435334',
          '&.Mui-focused': {
            color: '#435334'
          }
        }
      }}
    >
      <Autocomplete
        disablePortal={!isMobile}
        fullWidth
        blurOnSelect={isMobile}
        options={items}
        getOptionLabel={(option) => option.name}
        onChange={handleItemChange}
        value={null}
        inputValue={searchQuery}
        onInputChange={(event, newInputValue) => {
          setSearchQuery(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            inputRef={searchInputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder={isMobile ? "Tap to search..." : "Start typing to search..."}
          />
        )}
        PaperComponent={({ children, ...props }) => (
          <Paper
            {...props}
            elevation={8}
            sx={{
              borderRadius: '12px',
              mt: 1,
              border: '1px solid rgba(158, 179, 132, 0.2)',
              '& .MuiAutocomplete-listbox': {
                padding: '8px',
                '& .MuiAutocomplete-option': {
                  borderRadius: '8px',
                  margin: '4px 0',
                  padding: isMobile ? '12px 16px' : '8px 16px',
                  '&[aria-selected="true"]': {
                    backgroundColor: 'rgba(67, 83, 52, 0.08)',
                  },
                  '&.Mui-focused': {
                    backgroundColor: 'rgba(67, 83, 52, 0.12)',
                  }
                }
              }
            }}
          >
            {children}
          </Paper>
        )}
      />
    </Box>
  );
}

export default Search;